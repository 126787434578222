import React, { useEffect, useState } from "react"
import s from "./FilesView.module.scss"
import Image from "next/image"

const FilesView = ({ hapeTokenId, allFiles, isMobile }) => {
	const [files, setFiles] = useState(null)
	useEffect(() => {
		if (allFiles) {
			const maxGridItems = 24
			const toGenerate = maxGridItems - allFiles.length
			const extras = Array.from({ length: toGenerate }, () => {
				return {}
			})

			setFiles([...allFiles, ...extras])
		}
	}, [allFiles])
	return (
		<div className={s.files_view_container}>
			<div className={s.grid_container}>
				{files ? (
					files.map(f => {
						return (
							<div
								className={s.file_container}
								key={f?.key}
								onClick={() => {
									if (f?.imageLink) {
										window.open(f.imageLink, "_blank")
									}
								}}
							>
								<div className={s.thumbnail}>
									<div className={s.file_label_container}>
										<div className={s.file_text}>{f?.label}</div>
									</div>
									{f?.key ? (
										<Image
											id={"thumbnail"}
											src={`/static/img/files_view/${f?.key}.png`}
											alt={"Loading..."}
											width={300}
											height={300}
											quality={100}
											priority
										></Image>
									) : (
										<div className={s.empty_image}></div>
									)}
								</div>
							</div>
						)
					})
				) : (
					<div className={s.loading}>Loading Files...</div>
				)}
			</div>
		</div>
	)
}

export default FilesView
