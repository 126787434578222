import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react"
import s from "./Equipment2.module.scss"
import p from "../Modal.module.scss"
import Image from "next/image"
import Head from "next/head"
import WalletConnectIsolated from "../../WalletConnect/isolated"
import {
	motion,
	AnimatePresence,
	useAnimation,
	useAnimationControls,
} from "framer-motion"
import { ItemType } from "../../../../utils/enum/constants"
import BadgeWristbandInventory from "./badge-wristband-inventory-view"
import {
	itemNames,
	getEquipmentFolder,
	getEquipmentObject,
} from "../../../../utils/enum/constants"
import ChangingRoomWallet from "../../WalletConnect/changingRoomWallet"
import { WalletContext } from "../../../contexts/WalletContext"
import { ModalContext } from "../../../contexts/ModalContext"
import { IoClose } from "react-icons/io5"
import SlidingPane from "react-sliding-pane"
import "react-sliding-pane/dist/react-sliding-pane.css"
import ItemsSection from "./views/items-section"
import {
	allWearableCategories,
	getThumbnailImageUri,
	getExportFormatForUpperBodyGenerator,
	getExportFormatForUpperBodyGeneratorMinimal,
	getFormatForUpperBodyGenerator,
	getFormatForUpperBodyGeneratorMinimal,
	getLocalTraitsAfterEquip,
	getLocalTraitsAfterRemoval,
	getTraitsAfterEquip,
	getTraitsAfterRemoval,
	hapeGeneratorUri,
	isHandmade,
	checkCny2024Traits,
	handleSpecialTraits,
} from "../../../../utils/dynamic-equipment/helpers"
import { GeneratorTrait, MinimalTrait } from "../../../types"
import MobileEquipment from "./views/mobile-equipment"
import ItemTitle from "./views/item-title"
import BottomActions from "./views/bottom-actions"
import ClosetPanel from "../equip-dynamic/ClosetPanel"
import {
	activeCategoryAtom,
	hapeIdAtom,
	inventoryAtom,
	useCategorizedInventory,
	useEquipTrait,
	walletAddressAtom,
} from "../../../../services/fashion-index/inventoryServices"
import { useAtom } from "jotai"

const EquipViewTwo = ({
	card,
	hapeTraits,
	equippedEquipment,
	loadingEquipment,
	setReloadEquipment,
	setModalCardEquippedEquipment,
	setStats,
	setOldStats,
	isMobile,
	setIsSubModalOpenToMain,
	windowSize,
	setIsUpdatingRank,
	setPrimaryImageSrc,
	setExportImageSrc,
	exportImageComponent,
	setIsCloseModalVisible,
	setGradientLayers,
}) => {
	const { account, equipment, equipmentSignature } = useContext(WalletContext)

	const { APP_URI } = process.env
	const items = ["wristbands", "badges"]
	const [inventoryView, setInventoryView] = useState({})
	const [currentTab, setCurrentTab] = useState("wristband")

	const [unavailableWristbands, setUnavailableWristbands] = useState(null)
	const [equippedWristband, setEquippedWristband] = useState(null)
	const [availableWristbands, setAvailableWristbands] = useState(null)

	const [unavailableBadges, setUnavailableBadges] = useState(null)
	const [equippedBadge, setEquippedBadge] = useState(null)
	const [availableBadges, setAvailableBadges] = useState(null)

	const wristbandDimensions = { height: 100, width: 208 }
	const badgeDimensions = { height: 200, width: 200 }

	// Legacy referenced as card.token_id
	const hapeTokenId = card?.token_id

	const getDimensions = (itemType) => {
		if (itemType === ItemType.Wristband) {
			return wristbandDimensions
		} else if (itemType === ItemType.Badge) {
			return badgeDimensions
		}
	}

	const getEquipmentDisplayName = (blockchainName) => {
		return itemNames.find((w) => w.blockchainName === blockchainName).display
	}

	useEffect(() => {
		if (equippedEquipment) {
			const eW = equippedEquipment.wristbands.equipped.find(
				(w) => w.equippedOnHapePrime === card.token_id,
			)
			setEquippedWristband(eW)

			const eB = equippedEquipment.badges.equipped.find(
				(b) => b.equippedOnHapePrime === card.token_id,
			)
			setEquippedBadge(eB)
		}
	}, [card.token_id, equippedEquipment])

	useEffect(() => {
		if (equipment) {
			items.forEach((item) => {
				const eI = equipment[item].equipped.find(
					(w) => w.equippedOnHapePrime === card.token_id,
				)

				const unavailableItems = equipment[item].equipped.filter(
					(e) => e.equippedOnHapePrime !== card.token_id,
				)

				const uI = equipment[item].unequipped

				// Removes undefined when there's no equipped item
				const availableItems = [eI, ...uI].filter((w) => w !== undefined)
				if (item === "wristbands") {
					setEquippedWristband(eI)
					setUnavailableWristbands(unavailableItems)
					setAvailableWristbands(availableItems)
				} else if (item === "badges") {
					setEquippedBadge(eI)
					setUnavailableBadges(unavailableItems)
					setAvailableBadges(availableItems)
				}
			})
		} else {
			setUnavailableWristbands(null)
			setAvailableWristbands(null)
			setUnavailableBadges(null)
			setAvailableBadges(null)
		}
	}, [card.token_id, equipment])

	const equipWristband = async (u, itemType) => {
		if (equipment) {
			setIsUpdatingRank(true)

			let equippedTokenId

			if (itemType === ItemType.Wristband) {
				if (equippedWristband?.tokenId === u?.tokenId) {
					setEquippedWristband(null)
					equippedTokenId = null
				} else {
					setEquippedWristband(u)
					equippedTokenId = u.tokenId
				}
			} else if (itemType === ItemType.Badge) {
				if (equippedBadge?.tokenId === u?.tokenId) {
					setEquippedBadge(null)
					equippedTokenId = null
				} else {
					setEquippedBadge(u)
					equippedTokenId = u.tokenId
				}
			}

			const badgeEquip = {
				equippedOnHapePrime: card.token_id,
				tokenId:
					itemType === ItemType.Badge
						? equippedTokenId
						: equippedBadge?.tokenId,
			}

			const wristbandEquip = {
				equippedOnHapePrime: card.token_id,
				tokenId:
					itemType === ItemType.Wristband
						? equippedTokenId
						: equippedWristband?.tokenId,
			}
			const localBadgeEquip = {
				...badgeEquip,
				itemType:
					ItemType.Badge === itemType ? u.itemType : equippedBadge?.itemType,
			}
			const localWristbandEquip = {
				...wristbandEquip,
				itemType:
					ItemType.Wristband === itemType
						? u.itemType
						: equippedWristband?.itemType,
			}

			// The weird logic is to check if it's an equip or unequip
			setModalCardEquippedEquipment({
				wristband:
					itemType === ItemType.Wristband
						? equippedTokenId
							? localWristbandEquip
							: null
						: localWristbandEquip,
				badge:
					itemType === ItemType.Badge
						? equippedTokenId
							? localBadgeEquip
							: null
						: localBadgeEquip,
			})

			const changeSuccess = await fetch(`/api/inventory/changeEquipment`, {
				method: "POST",
				body: JSON.stringify({
					walletAddress: equipment.walletAddress,
					hapeTokenId: card.token_id,
					wristbandEquip: wristbandEquip,
					badgeEquip: badgeEquip,
					signature: equipmentSignature,
				}),
			})
				.then((response) => response.json())
				.then((res) => res.data)

			setReloadEquipment(true)

			if (changeSuccess) {
				const updatedStats = await fetch(
					`/api/inventory/getUpdatedStats?hapePrimeTokenId=${card.token_id}`,
					{ method: "GET" },
				)
					.then((response) => response.json())
					.then((res) => res.data)

				setStats((prevState) => {
					setOldStats(prevState)
					return updatedStats
				})
			} else {
				if (itemType === ItemType.Wristband) {
					setEquippedWristband(null)
				} else if (itemType === ItemType.Badge) {
					setEquippedBadge(null)
				}
				setModalCardEquippedEquipment(null)
			}
			setIsUpdatingRank(false)
		}
	}

	const [isMoreThanMax, setIsMoreThanMax] = useState(false)

	useEffect(() => {
		const fillerGridWristbands = getGrid(
			availableWristbands,
			unavailableWristbands,
		)
		const fillerGridBadges = getGrid(availableBadges, unavailableBadges)
		setInventoryView({
			wristband: (
				<BadgeWristbandInventory
					equipment={equipment}
					availableItems={availableWristbands}
					unavailableItems={unavailableWristbands}
					equipItem={equipWristband}
					equippedItem={equippedWristband}
					getEquipmentDisplayName={getEquipmentDisplayName}
					getEquipmentObject={getEquipmentObject}
					getEquipmentFolder={getEquipmentFolder}
					getDimensions={getDimensions}
					itemType={ItemType.Wristband}
					fillerGrid={fillerGridWristbands}
					isMobile={isMobile}
					isMoreThanMax={isMoreThanMax}
				/>
			),
			badge: (
				<BadgeWristbandInventory
					equipment={equipment}
					availableItems={availableBadges}
					unavailableItems={unavailableBadges}
					equipItem={equipWristband}
					equippedItem={equippedBadge}
					getEquipmentDisplayName={getEquipmentDisplayName}
					getEquipmentObject={getEquipmentObject}
					getEquipmentFolder={getEquipmentFolder}
					getDimensions={getDimensions}
					itemType={ItemType.Badge}
					fillerGrid={fillerGridBadges}
					isMobile={isMobile}
					isMoreThanMax={isMoreThanMax}
				/>
			),
		})
	}, [
		equipment,
		availableWristbands,
		unavailableWristbands,
		equippedWristband,
		availableBadges,
		unavailableBadges,
		equippedBadge,
		isMobile,
		isMoreThanMax,
	])

	const getGrid = (available, unavailable) => {
		let maxGridItems = 18
		if (isMobile) {
			maxGridItems = 27
		}
		let toGenerate

		const ownedLengths = (available?.length ?? 0) + (unavailable?.length ?? 0)

		if (ownedLengths > maxGridItems) {
			maxGridItems = ownedLengths
			setIsMoreThanMax(true)
		}

		toGenerate = maxGridItems - ownedLengths
		if (toGenerate < 0) {
			return []
		} else {
			return Array.from({ length: toGenerate }, () => {
				return {}
			})
		}
	}

	const subModalControls = useAnimation()
	const [isSubModalOpen, setIsSubModalOpen] = useState(false)

	const closeSubModal = async (e) => {
		e.preventDefault()
		setIsSubModalOpenToMain(false)
		await triggerSubModalClose()
		setIsSubModalOpen(false)
	}

	useEffect(() => {}, [])

	const openSubModal = async () => {
		setIsSubModalOpen(true)
		setIsSubModalOpenToMain(true)
		await triggerSubModalOpen()
	}
	const triggerSubModalOpen = async () => {
		await subModalControls.start({
			right: 0,
			zIndex: 89,
			opacity: 1,
			transition: { duration: 0.35 },
		})
	}

	const triggerSubModalClose = async () => {
		await subModalControls.start({
			right: "100%",
			opacity: 0,
			transition: { duration: 0.35 },
		})
	}

	const [isPaneOpen, setIsPaneOpen] = useState(false)
	const categories = ["Accessory", "Clothing", "Eyewear"]

	const [hapeUri, setHapeUri] = useState("")
	const imageRef = useRef<HTMLImageElement | null>(null)
	const [dynamicImageSrc, setDynamicImageSrc] = useState(null)
	const [localExportImageSrc, setLocalExportImageSrc] = useState(null)

	const getHapeGeneratorUri = async (
		formattedTraits: GeneratorTrait[],
		exportImg?: boolean,
	) => {
		fetch(hapeGeneratorUri, {
			method: "POST",
			mode: "cors",
			body: JSON.stringify({
				fmt: "png",
				attributes: formattedTraits,
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				const formattedSrc = "data:image.+;base64," + data["image"]

				if (exportImg) {
					setExportImageSrc(formattedSrc)
					setLocalExportImageSrc(formattedSrc)
				} else {
					setDynamicImageSrc(formattedSrc)
					setPrimaryImageSrc(formattedSrc)
				}

				if (imageRef.current) {
					imageRef.current.src = "data:image.+;base64," + data["image"]
				}
			})
	}

	const getExportDynamicHapeUri = () => {
		const traitsToFormat = getDeepCopy(hapeTraits)
		const formattedTraits = getExportFormatForUpperBodyGenerator(
			traitsToFormat,
			card.token_id,
		)
		console.log("export", formattedTraits)
		getHapeGeneratorUri(formattedTraits, true)
	}

	const { setModalColorsAsCNY } = useContext(ModalContext)
	/**
	 * If enhanced background is bananas, don't generate image with bananas
	 * .. instead, let the modal card background handle it
	 * If enhanced background is anything else (violet waves),
	 * .. disable modal card backgrounds, and populate image with it
	 * @param traits
	 * @returns
	 */

	// const getDynamicHapeUri = () => {
	// 	const traitsToFormat = getDeepCopy(hapeTraits)
	// 	let formattedTraits = getFormatForUpperBodyGenerator(
	// 		traitsToFormat,
	// 		card.token_id
	// 	)
	// 	formattedTraits = checkEnhancedBackground(formattedTraits)
	// 	getHapeGeneratorUri(formattedTraits)
	// }

	const [panelItems, setPanelItems] = useState({})
	const [activeCategory, setActiveCategory] = useAtom(activeCategoryAtom)
	const [disabledCategories, setDisabledCategories] = useState([])

	const [allNftEquipment, setAllNftEquipment] = useState(null)
	const [originalNativeTraits, setOriginalNativeTraits] = useState(null)
	const [equippedNativeTraits, setEquippedNativeTraits] = useState(null)

	const [currentlyEquipped, setCurrentlyEquipped] = useState(null)
	const [hoveredTrait, setHoveredTrait] = useState(null)

	const itemsSectionRef = useRef(null)

	useEffect(() => {
		if (itemsSectionRef.current) {
			itemsSectionRef.current.scrollTop = 0
		}
	}, [activeCategory])

	// useEffect(() => {
	// 	const legacyParsedCard = { ...card, token_id: parseInt(card.token_id) }
	// 	if (!isHandmade(parseInt(legacyParsedCard.token_id))) {
	// 		getDynamicHapeUri()
	// 		getExportDynamicHapeUri()
	// 		setModalColorsAsNative(legacyParsedCard)
	// 	}
	// }, [])

	// const updateUpperBodyGenerator = async (
	// 	traitsAfterAlteration: GeneratorTrait[]
	// ) => {
	// 	// Processes Hape Traits (Class system for Haircuts, Headwear, etc.)
	// 	let traitsForUpperBodyGenerator = getFormatForUpperBodyGeneratorMinimal(
	// 		traitsAfterAlteration,
	// 		card.token_id
	// 	)
	// 	// Checks for CNY2024 Traits to modify UI (bg color, lanterns, etc)
	// 	checkCny2024Traits(
	// 		traitsForUpperBodyGenerator,
	// 		setModalColorsAsCny2024,
	// 		() => setModalColorsAsNative(card)
	// 	)
	// 	// Further processing for Enhanced BG Stuff
	// 	traitsForUpperBodyGenerator = checkEnhancedBackground(
	// 		traitsForUpperBodyGenerator
	// 	)

	// 	// Processed for Export
	// 	let exportTraitsForUpperBodyGenerator =
	// 		getExportFormatForUpperBodyGeneratorMinimal(
	// 			traitsAfterAlteration,
	// 			card.token_id
	// 		)

	// 	exportTraitsForUpperBodyGenerator = checkEnhancedBackground(
	// 		exportTraitsForUpperBodyGenerator
	// 	)
	// 	exportTraitsForUpperBodyGenerator = handleSpecialTraits(
	// 		exportTraitsForUpperBodyGenerator
	// 	)

	// 	await getHapeGeneratorUri(traitsForUpperBodyGenerator)
	// 	await getHapeGeneratorUri(exportTraitsForUpperBodyGenerator, true)
	// }

	// const equipNativeTrait = async (
	// 	tokenId: number,
	// 	nativeTrait: MinimalTrait
	// ) => {
	// 	const newTrait = nativeTrait
	// 	const localTraitsAfterEquip = getLocalTraitsAfterEquip(
	// 		currentlyEquipped,
	// 		newTrait
	// 	)
	// 	console.log("natuve")
	// 	console.log(nativeTrait)
	// 	setCurrentlyEquipped(localTraitsAfterEquip)
	// 	const traitsAfterEquip = getTraitsAfterEquip(
	// 		localTraitsAfterEquip,
	// 		newTrait
	// 	)
	// 	updateUpperBodyGenerator(traitsAfterEquip)
	// 	// need a standardized way to do this
	// 	// setCurrentlyEquipped to update
	// }

	// const equipItem = async (tokenId: number, newTrait: MinimalTrait) => {
	// 	const localTraitsAfterEquip = getLocalTraitsAfterEquip(
	// 		currentlyEquipped,
	// 		newTrait
	// 	)
	// 	setCurrentlyEquipped(localTraitsAfterEquip)
	// 	const traitsAfterEquip = getTraitsAfterEquip(
	// 		localTraitsAfterEquip,
	// 		newTrait
	// 	)
	// 	updateUpperBodyGenerator(traitsAfterEquip)

	// 	// need a standardized way to do this
	// 	// setCurrentlyEquipped to update
	// }

	// const removeItem = async (categoryToRemove: string) => {
	// 	const localTraitsAfterRemoval = getLocalTraitsAfterRemoval(
	// 		currentlyEquipped,
	// 		categoryToRemove
	// 	)
	// 	setCurrentlyEquipped(localTraitsAfterRemoval)
	// 	const traitsAfterRemoval = getTraitsAfterRemoval(
	// 		localTraitsAfterRemoval,
	// 		categoryToRemove
	// 	)
	// 	updateUpperBodyGenerator(traitsAfterRemoval)
	// }

	const resetHape = async () => {
		const response = await fetch(`/api/inventory/dynamic/resetClothedHape`, {
			method: "POST",
			body: JSON.stringify({
				hapeTokenId: card.token_id,
			}),
		})
	}

	//API for currently equipped clothes
	useEffect(() => {
		// Temporary
		setCurrentlyEquipped(hapeTraits)
	}, [])

	const getEntireCloset = async () => {
		// const allEquipment = await fetch(
		// 	`/api/inventory/dynamic/getDynamicEquipment?hapePrimeTokenId=${
		// 		card.token_id
		// 	}&account=${`0x2Ac07651Ef04EF5364d1db5552b3016C08DCeE68`}`,
		// 	{
		// 		method: "GET"
		// 	}
		// )
		// 	.then(response => response.json())
		// 	.then(response => response.data)
		// setAllNftEquipment(allEquipment)

		const originalNativeTraits = await fetch(
			`/api/inventory/dynamic/getOriginalNativeTraits?hapeTokenId=${card.token_id}`,
			{
				method: "GET",
			},
		)
			.then((response) => response.json())
			.then((response) => response.data)
		setOriginalNativeTraits(originalNativeTraits)

		const equippedNativeTraits = await fetch(
			`/api/inventory/dynamic/getEquippedNativeTraits?hapeTokenId=${card.token_id}`,
			{
				method: "GET",
			},
		)
			.then((response) => response.json())
			.then((response) => response.data)
		setEquippedNativeTraits(equippedNativeTraits)
	}

	useEffect(() => {
		if (equipment) {
			setAllNftEquipment(equipment)
			getEntireCloset()
		}
	}, [equipment])

	// useEffect(() => {
	// 	const panel = allWearableCategories.reduce((accumulator, value) => {
	// 		return {
	// 			...accumulator,
	// 			[value]: (
	// 				<ItemsSection
	// 					category={value}
	// 					originalNativeTraits={originalNativeTraits}
	// 					equippedNativeTraits={equippedNativeTraits}
	// 					equipNativeTrait={equipNativeTrait}
	// 					items={allNftEquipment}
	// 					equipItem={equipItem}
	// 					removeItem={removeItem}
	// 					setHoveredTrait={setHoveredTrait}
	// 					allCurrentlyEquippedTraits={currentlyEquipped}
	// 				/>
	// 			)
	// 		}
	// 	}, {})
	// 	setPanelItems(panel)
	// }, [
	// 	JSON.stringify(allNftEquipment),
	// 	JSON.stringify(originalNativeTraits),
	// 	JSON.stringify(equippedNativeTraits),
	// 	JSON.stringify(currentlyEquipped)
	// ])

	const equipPaneControls = useAnimationControls()

	const [isShowingEquipmentPanel, setIsShowingEquipmentPanel] = useState(false)

	const backToEquip = () => {
		setIsShowingEquipmentPanel(false)
		setIsCloseModalVisible(true)
	}

	const backToMainMenu = () => {
		setActiveCategory(null)
	}

	const onChangeActiveCategory = (category: string) => {
		setActiveCategory(category)
	}

	const { data: inventory } = useCategorizedInventory()

	return (
		<>
			{isMobile && (
				<BottomActions
					activeCategory={activeCategory}
					backToEquip={backToEquip}
					backToMainMenu={backToMainMenu}
					hapeTokenId={card.token_id}
					isShowingEquipmentPanel={isShowingEquipmentPanel}
					localExportImageSrc={localExportImageSrc}
					setIsCloseModalVisible={setIsCloseModalVisible}
					setIsShowingEquipmentPanel={setIsShowingEquipmentPanel}
					equipment={equipment}
				/>
			)}
			{isMobile && isShowingEquipmentPanel && (
				<MobileEquipment
					panelItems={panelItems}
					activeCategory={activeCategory}
					setActiveCategory={setActiveCategory}
					hapeTokenId={card.token_id}
					itemTitle={<ItemTitle item={hoveredTrait} />}
				/>
			)}
			{!isMobile && (
				<>
					<motion.div
						key={"equip2"}
						initial={{ translateX: "100%" }}
						animate={{
							translateX: "0%",
							transition: {
								ease: "linear",
							},
						}}
						exit={{ translateX: 150 }}
						className={s.pane}
					>
						{inventory ? (
							<ItemTitle item={hoveredTrait} />
						) : (
							<div
								className={`${s.item_details_section}`}
								style={{
									backgroundImage: `url(/static/img/logos/hape_rebrand_logo_white.svg)`,
									backgroundSize: "30%",
									backgroundPositionX: "55%",
									backgroundPositionY: "51%",
								}}
							></div>
						)}
						<ClosetPanel
							activeCategory={activeCategory}
							allWearableCategories={allWearableCategories}
							handleActiveCategoryChange={onChangeActiveCategory}
							hapeTokenId={card.token_id}
						/>
						{/* <div className={s.main_container}>
							<div className={s.categories}>
								{Object.keys(panelItems).map(category => {
									return (
										<div
											onClick={() => setActiveCategory(category)}
											className={`${s.category} ${
												activeCategory === category ? s.active_category : ""
											}`}
											key={category}
										>
											<div className={s.icon_category}>
												<div className={s.icon}>
													<img
														src={`/static/img/white/${category}.svg`}
														width={25}
														height={25}
														alt={category}
													/>
												</div>
												<div>{category}</div>
											</div>
										</div>
									)
								})}
								<div
									onClick={() => {}}
									style={{
										justifyContent: "center",
										backgroundColor: "white",
										margin: "auto",
										width: "90%",
										height: "2rem"
									}}
									className={s.category}
									key={"Reset"}
								>
									<div style={{ fontSize: "1.2rem", color: "black" }}>BETA</div>
								</div>
								{Boolean(process.env.NEXT_PUBLIC_IS_TESTING) && (
									<ChangingRoomWallet hapeTokenId={card.token_id} />
								)}
							</div>
							<div className={s.items_section_parent}>
								<div
									ref={itemsSectionRef}
									className={s.items_section_container}
								>
									<div>
										{!isHandmade(card.token_id) ? (
											<ChangingRoomWallet hapeTokenId={card.token_id} />
										) : (
											<div
												style={{
													fontSize: "0.8rem",
													padding: "1rem",
													textAlign: "center",
													color: "grey"
												}}
											>
												Handmade HAPEs are currently going through some extra
												love and care before being ready to get dressed! We
												apologize for the inconvenience.
											</div>
										)}
										{!allNftEquipment && (
											<div>
												<div
													style={{
														marginTop: 50,
														color: "white",
														padding: "0rem 2rem",
														fontSize: "0.8rem",
														textAlign: "center"
													}}
													className={s.text_container}
												>
													<div style={{ fontSize: "1rem", fontWeight: 600 }}>
														Welcome to the Hape Equipment Panel
													</div>
													<br />
													It may not look like it, but trait-swapping is
													incredibly complex. We’re trying to account for an
													unfathomable number possible item combinations, and
													we’re probably not going to get it right the first
													time around.
													<br />
													<br />
													<b>For the moment, your changes won’t be saved</b> -
													you’re able to export your current outfit, but once
													you leave the index window, you’ll have to get dressed
													again. We’re working on this first.
													<br />
													<br />
													<b>Secondly:</b> just like in real life, not all items
													work with other items. If something is layering in a
													weird way, or the interface is bugging out: please let
													us know in the Drops Discord channel. We’ll do our
													best to fix bugs as they come up.
													<br />
													<br />
													Consider yourselves fashion explorers as we build this
													beautiful engine together. Be patient, Stay Hape, and
													have fun!
												</div>
											</div>
										)}
									</div>

									{equipment && activeCategory && panelItems[activeCategory]}
								</div>
							</div>
						</div> */}
					</motion.div>
				</>
			)}
		</>
	)
}

function getDeepCopy(object: Object) {
	return JSON.parse(JSON.stringify(object))
}

export default EquipViewTwo
