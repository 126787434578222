import React, {
	useEffect,
	useState,
	useCallback,
	useRef,
	useMemo,
	useContext,
	SetStateAction,
	Dispatch,
	FC,
} from "react"
import Image from "next/image"

import { ModalContext } from "../../contexts/ModalContext"
import s from "./Modal.module.scss"
import colorMap from "../Helpers/ColorMap"
import {
	motion,
	AnimatePresence,
	useAnimation,
	DragControls,
	AnimationControls,
} from "framer-motion"
import { IoClose } from "react-icons/io5"
import { AiOutlineQuestionCircle } from "react-icons/ai"
import ModalViewContainer from "./modal-view-container"
import gradients from "../../../zHapeStorage/gradientsFromGradientHapes"
import duotoneGradientHapes from "../../../zHapeStorage/duotoneGradientHapes"
import {
	isolatedHapesAwsUrl,
	itemNames,
	tabletWidth,
} from "../../../utils/enum/constants"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import useImagePreloader from "../../hooks/useImagePreloader"
import CountUp from "react-countup"
import { Transition, TransitionGroup } from "react-transition-group"
import {
	getHapeGradient,
	getHapeGradientHelper,
} from "../../../utils/hape/hapeHelper"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import PointsVotes from "./points-votes"
import {
	Equipment,
	Hape,
	ModalCardEquippedEquipment,
	ProfileEquipmentDetails,
} from "../../types"
import { fashionIndexApi } from "../../../utils/fashion-index/api"
import { useAtom, useSetAtom } from "jotai"
import {
	hapeIdAtom,
	modalActiveTabAtom,
	useDavenator,
	useGetDavenatorExportImageForDownload,
	useGetHapeStats,
	walletAddressAtom,
} from "../../../services/fashion-index/inventoryServices"
import {
	isCny24LanternsEnhancedBackgroundActivatedAtom,
	layeredExtraAtom,
	leftExtraAtom,
	rightExtraAtom,
	useResetModalLayersState,
} from "../../../store/global/ModalCardOverlayState"
import { WalletContext } from "../../contexts/WalletContext"
import { TabName } from "../../types/fashionIndex/HapeModalTypes"
import useDavenatorToast from "../HotToast/useDavenatorToast"
import { HapeDetails } from "../../types/fashionIndex/InventoryTypes"

interface ModalCardProps {
	isMobile: boolean
	card: HapeDetails
	setIsFilterSidebarOpen: Dispatch<SetStateAction<boolean>>
	modalControls: AnimationControls
	triggerOpen: () => Promise<void>
	triggerClose: () => Promise<void>
	windowSize: any
	isIOS: boolean
	isTablet: boolean
}

const ModalCard: FC<ModalCardProps> = ({
	isMobile,
	card,
	setIsFilterSidebarOpen,
	modalControls,
	triggerOpen,
	triggerClose,
	windowSize,
	isIOS,
	isTablet,
}) => {
	const {
		data: davenatorImageSrc,
		isSuccess: isDavenatorImageLoaded,
		isError: isErrorDavenatorImageSrc,
		isFetching: isPendingDaveantorImageSrc,
	} = useDavenator()

	useDavenatorToast(
		davenatorImageSrc,
		isPendingDaveantorImageSrc,
		isDavenatorImageLoaded,
		isErrorDavenatorImageSrc,
	)

	const [leftExtra] = useAtom(leftExtraAtom)
	const [rightExtra] = useAtom(rightExtraAtom)
	const [layeredExtra] = useAtom(layeredExtraAtom)
	const [isCny24LanternsEnhancedBackgroundActivated] = useAtom(
		isCny24LanternsEnhancedBackgroundActivatedAtom,
	)

	const setHapeId = useSetAtom(hapeIdAtom)
	setHapeId(card.token_id.toString())

	const { account } = useContext(WalletContext)
	const setWalletAddress = useSetAtom(walletAddressAtom)
	setWalletAddress(account)

	const rank = 0
	const preloadSrcList: string[] = [
		`/static/img/files_view/curry.png`,
		`/static/img/files_view/high_snobiety.png`,
		`/static/img/files_view/isolated.png`,
		`/static/img/files_view/jagermeister_apparel.png`,
		`/static/img/files_view/jagermeister_bottles.png`,
		`/static/img/files_view/pfp.png`,
		`/static/img/files_view/twitter_header_1.png`,
		`/static/img/files_view/twitter_header_2.png`,
	]

	const { imagesPreloaded } = useImagePreloader(preloadSrcList)

	const getImageDimensions = () => {
		return isMobile
			? Math.round(windowSize.height)
			: Math.round(windowSize.height)
	}

	const formatImageUrl = (card) => {
		return `${isolatedHapesAwsUrl}/${getImageDimensions()}x${getImageDimensions()}/${
			card.token_id
		}.png`
	}
	const [imageSrc, setImageSrc] = useState(
		card ? formatImageUrl(card) : `/static/img/placeholderhape.png`,
	)
	const [blurImgSrc, setBlurImgSrc] = useState(
		// Load the same preloaded image as the card
		card
			? `${isolatedHapesAwsUrl}/300x300/${card.token_id}.png`
			: `/static/img/placeholderhape.png`,
	)

	const [badgeSrc, setBadgeSrc] = useState(null)

	const [wornBadgeSrc, setWornBadgeSrc] = useState(null)
	const [exportSrc, setExportSrc] = useState(
		`https://meta.hapeprime.com/${card.token_id}.png`,
	)

	const [equippedEquipment, setEquippedEquipment] = useState(null)
	const [modalCardEquippedEquipment, setModalCardEquippedEquipment] =
		useState<ModalCardEquippedEquipment>(null)
	const [equippedBadge, setEquippedBadge] = useState(null)
	const [isBadgeLoaded, setIsBadgeLoaded] = useState(false)
	const [isWornBadgeLoaded, setIsWornBadgeLoaded] = useState(false)
	const [exportImageSrc, setExportImageSrc] = useState(null)

	const [oldStats, setOldStats] = useState(null)
	const {
		setModalColorsAsNative,
		hapeModalGradient,
		setHapeModalGradient,
		setHapeColor,
		setCurrentHapeTokenId,
	} = useContext(ModalContext)
	const [isCloseModalVisible, setIsCloseModalVisible] = useState(true)

	const { data: stats } = useGetHapeStats()

	useEffect(() => {
		document.addEventListener("keydown", onKeyDown)

		setIsFilterSidebarOpen(false)

		// setModalColorsAsNative(card)

		setCurrentHapeTokenId(card.token_id)
	}, [])

	const setModalActiveTabGlobal = useSetAtom(modalActiveTabAtom)
	const [modalTab, setModalTab] = useState("traits")
	const [modalView, setModalView] = useState({})
	const [isLoadingHapeData, setIsLoadingHapeData] = useState(false)

	const tabContainerControls = useAnimation()

	const changeTab = (tab) => {
		if (tab === "equip" && !isMobile) {
			tabContainerControls.start({
				translateX: "-44vw",
				transition: {
					ease: "linear",
				},
			})
		} else {
			tabContainerControls.start({
				translateX: 0,
			})
		}
		setModalTab(tab)
		console.log("change tab", tab, TabName[tab])
		setModalActiveTabGlobal(TabName[tab])
	}
	const tabContainer = useMemo(
		() => (
			<motion.div
				animate={tabContainerControls}
				className={`${s.modal__tab_container} ${
					isMobile ? s.modal__tab_container_no_margins : ""
				}`}
			>
				{Object.keys(modalView).map((key) => {
					const capitalizedKey = key[0].toUpperCase() + key.slice(1)
					if (modalTab !== key) {
						return (
							<button key={key} onClick={() => changeTab(key)}>
								{capitalizedKey}
							</button>
						)
					} else {
						return (
							<motion.div key={key} className={`${s.modal__selected_tab}`}>
								<span></span>
								<button onClick={() => changeTab(key)}>{capitalizedKey}</button>
							</motion.div>
						)
					}
				})}
			</motion.div>
		),
		[modalView, modalTab],
	)

	const [isBaseHapeVisible, setIsBaseHapeVisible] = useState(true)
	const badgeControls = useAnimation()
	const wornBadgeControls = useAnimation()

	const getIsAnimationAssetsLoaded = () => {
		return isBadgeLoaded && isWornBadgeLoaded
	}

	const getFlyInBadge = (badgeEquipped) => {
		const flyInBadge = itemNames.find(
			(i) => i.blockchainName === badgeEquipped.itemType,
		).animated

		return `/static/img/equipment/${flyInBadge.folderName}/${flyInBadge.fileName}.png`
	}

	const getWornBadgeImgSrc = async (
		equippedBadgeType,
		c?: number,
		isExport: boolean = false,
	) => {
		let awsUrl

		if (!isExport) {
			awsUrl = itemNames.find(
				(i) => i.blockchainName === equippedBadgeType,
			).baseAwsUrl
		} else {
			awsUrl = itemNames.find(
				(i) => i.blockchainName === equippedBadgeType,
			).exportAwsUrl
		}

		let dim = c ?? getImageDimensions()

		const wornBadgeSrc = await fetch(
			`/api/inventory/getBadgedHapeSrc?size=${dim}&tokenId=${card.token_id}&awsUrl=${awsUrl}&isExport=${isExport}`,
		)
			.then((response) => response.json())
			.then((json) => json.data)
		return wornBadgeSrc
	}

	useEffect(() => {
		const animateBadge = async () => {
			// setIsWornBadgeLoaded(false)

			// setIsBaseHapeVisible(true)
			// setWornBadgeSrc(null)
			const badgeEquipped = modalCardEquippedEquipment?.badge
			if (badgeEquipped?.tokenId !== equippedBadge?.tokenId) {
				await triggerBadgeAnimation(null)

				setEquippedBadge(badgeEquipped)
				if (badgeEquipped) {
					setBadgeSrc(getFlyInBadge(badgeEquipped))
					const src = await getWornBadgeImgSrc(badgeEquipped.itemType)
					setWornBadgeSrc(src)

					const exSrc = await getWornBadgeImgSrc(
						badgeEquipped.itemType,
						2160,
						true,
					)
					setExportSrc(exSrc)
				}
			}
		}

		//animateBadge()
	}, [modalCardEquippedEquipment])

	const { APP_URI } = process.env
	useEffect(() => {
		const fetchEquipment = async () => {
			const equippedBadge = await fetch(
				`/api/inventory/getInventoryUnsynced?hapePrimeTokenId=${card.token_id}`,
			)
				.then((response) => response.json())
				.then((json) => json.data)
			setEquippedBadge(equippedBadge)
			if (equippedBadge) {
				setBadgeSrc(getFlyInBadge(equippedBadge))
				const src = await getWornBadgeImgSrc(equippedBadge.itemType)
				setWornBadgeSrc(src)

				const exSrc = await getWornBadgeImgSrc(
					equippedBadge.itemType,
					2160,
					true,
				)
				setExportSrc(exSrc)
			}
		}

		//fetchEquipment()
	}, [])

	const [isSubModalOpen, setIsSubModalOpen] = useState(false)

	useEffect(() => {
		if (getIsAnimationAssetsLoaded() && !isSubModalOpen) {
			triggerBadgeAnimation(equippedBadge)
		}
	}, [
		equippedBadge,
		getIsAnimationAssetsLoaded(),
		isSubModalOpen,
		isWornBadgeLoaded,
	])

	const sparkleRef = useRef(null)
	const [sparkle, setSparkle] = useState(
		<video
			muted
			playsInline
			className={`${s.modal__badge_video}`}
			ref={sparkleRef}
		>
			<source
				src={`/static/img/equipment/badge_animated/misc/sparkle.mp4`}
				type='video/mp4; codecs="hvc1"'
			></source>
			<source
				src={`/static/img/equipment/badge_animated/misc/sparkle.webm`}
				type="video/webm"
			/>
		</video>,
	)

	const triggerBadgeAnimation = async (badgeEquipped) => {
		let x
		let y

		x = "-15%"
		y = "-60%"
		if (badgeEquipped) {
			await badgeControls.start({
				visibility: "hidden",
				x: x,
				y: y,
				opacity: 0,
				scale: 2.5,
			})
			sparkleRef.current.play()
			await badgeControls.start({
				visibility: "visible",
				x: 0,
				y: 0,
				opacity: 1,
				scale: 1,
				transition: {
					ease: "easeIn",
					duration: 0.15,
				},
				transitionEnd: {
					opacity: 0,
				},
			})
			badgeControls.start({
				opacity: 0,
			})
			await wornBadgeControls.start({
				visibility: "visible",
				opacity: 1,
				transition: {
					ease: "easeIn",
					duration: 0.075,
				},
			})
			badgeControls.start({ visibility: "hidden" })
			//setIsBaseHapeVisible(false)
		} else {
			//setIsBaseHapeVisible(true)
			await badgeControls.start({
				scale: 1,
				visibility: "hidden",
			})
			wornBadgeControls.start({
				visibility: "hidden",
				opacity: 0,
			})
			sparkleRef.current.pause()
			sparkleRef.current.currentTime = 0
		}
	}

	const leftSideRef = useRef(null)

	const [isShowingRankChangeIndicator, setIsShowingRankChangeIndicator] =
		useState(0)
	const [isUpdatingRank, setIsUpdatingRank] = useState(false)

	const [votes, setVotes] = useState(null)
	useEffect(() => {
		const getVotes = async () => {
			const tokenIds = JSON.stringify([parseInt(card.token_id)])
			const newVotes = await fetch(
				`/api/hapeornot/getVoteData?hapeTokenIds=${tokenIds}`,
			)
				.then((response) => response.json())
				.then((response) => response.data)
				.then((response) => response[0])

			setVotes(newVotes)
		}
		getVotes()
	}, [])

	const statsBox = useMemo(() => {
		const startScore = 0
		const endScore = 0

		let startRank = oldStats?.rank ?? rank

		let leadingZeroes = 4 - startRank?.toString().length ?? 0
		let zeroes = ""
		for (var i = 0; i < leadingZeroes; i++) {
			zeroes += "0"
		}
		startRank = zeroes + startRank

		let endRank = stats?.rank ?? 0

		zeroes = ""
		leadingZeroes = 4 - endRank.toString()?.length ?? 0
		for (var i = 0; i < leadingZeroes; i++) {
			zeroes += "0"
		}

		endRank = zeroes + endRank
		return (
			<StatsBox
				startRank={startRank}
				startScore={startScore}
				endRank={endRank}
				endScore={endScore}
				isShowingRankChangeIndicator={isShowingRankChangeIndicator}
				setIsShowingRankChangeIndicator={setIsShowingRankChangeIndicator}
				isUpdatingRank={isUpdatingRank}
				isMobile={isMobile}
				votes={votes}
			/>
		)
	}, [oldStats, stats, card, rank, isMobile, isUpdatingRank, votes])

	// useEffect(() => {
	// 	setOldStats({
	// 		score: stats?.score ?? Math.round(stats?.score),
	// 		rank: stats?.rank
	// 	})
	// }, [stats])

	const modalViewContainer = useMemo(() => {
		const startScore = 0
		const endScore = 0
		return (
			<ModalViewContainer
				isMobile={isMobile}
				card={card}
				equippedEquipment={equippedEquipment}
				modalCardEquippedEquipment={modalCardEquippedEquipment}
				setEquippedEquipment={setEquippedEquipment}
				setModalCardEquippedEquipment={setModalCardEquippedEquipment}
				exportSrc={exportSrc}
				tabContainer={tabContainer}
				modalTab={modalTab}
				modalView={modalView}
				setModalView={setModalView}
				statsBox={statsBox}
				setStats={() => {}}
				setOldStats={setOldStats}
				setIsSubModalOpen={setIsSubModalOpen}
				setIsUpdatingRank={setIsUpdatingRank}
				votes={votes}
				startScore={startScore}
				endScore={endScore}
				setImageSrc={setImageSrc}
				setExportImageSrc={setExportImageSrc}
				exportImageComponent={
					<DownloadImage
						exportImageSrc={exportImageSrc}
						tokenId={card.token_id}
					/>
				}
				setIsCloseModalVisible={setIsCloseModalVisible}
				setGradientLayers={() => {}}
				setIsLoadingHapeData={setIsLoadingHapeData}
			/>
		)
	}, [
		isMobile,
		exportSrc,
		equippedEquipment,
		leftSideRef,
		card,
		tabContainer,
		modalTab,
		modalView,
		setModalView,
		statsBox,
		votes,
		stats,
		oldStats,
		isLoadingHapeData,
	])

	const handleCloseModal = () => {
		triggerClose()
	}

	/**
	 * Close the modal when the Escape key is pressed
	 * @param {object} event
	 */
	const onKeyDown = (event) => {
		if (event.keyCode === 27) {
			handleCloseModal()
		}
	}

	useEffect(() => {
		triggerOpen()
	}, [])

	const [preloadBadges, setPreloadBadges] = useState([
		"/static/img/equipment/badge_animated/common.png",
		"/static/img/equipment/badge_animated/community.png",
		"/static/img/equipment/badge_animated/genesis.png",
		"/static/img/equipment/badge_animated/legendary.png",
		"/static/img/equipment/badge_animated/rare.png",
	])

	useEffect(() => {
		// itemNames.map(async (item, i) => {
		// 	if (item.blockchainName.includes("Badge")) {
		// 		const image = await getWornBadgeImgSrc(item.blockchainName)
		// 		setPreloadBadges(prevState => [...prevState, image])
		// 	}
		// })
	}, [])

	const isMobileAndOnEquipPanel = () => {
		return isMobile && modalTab === "equip"
	}

	const getCny24LanternsEnhancedBackgroundStyles = (): React.CSSProperties => {
		if (isCny24LanternsEnhancedBackgroundActivated) {
			const baseStyle: React.CSSProperties = {
				backgroundImage:
					"url('/static/img/backgrounds/cny24_lanterns_long.png')",
				backgroundPosition: "top left",
				backgroundSize: "cover",
			}

			if (isMobile) {
				// Define additional or modified styles for mobile devices
				return {
					backgroundImage:
						"url('/static/img/backgrounds/cny24_lanterns_long_mobile.png')",
					backgroundPosition: "top left",
					backgroundSize: "contain",
					backgroundRepeat: "no-repeat",
				}
			}

			return baseStyle
		}

		// Return empty object when cny24Lanterns is null or undefined
		return {}
	}

	const cny24LanternsEnhancedBackgroundStyles =
		getCny24LanternsEnhancedBackgroundStyles()

	const {
		data: exportImageSource,
		isSuccess: successfullyFetchedExportImageSource,
		isFetching: isFetchingExportImage,
	} = useGetDavenatorExportImageForDownload()

	return (
		<div>
			{isMobile && isIOS && (
				<motion.div
					animate={modalControls}
					className={s.behind}
					style={{
						background: hapeModalGradient,
					}}
				></motion.div>
			)}
			<motion.div className={s.pane} animate={modalControls}>
				<div className={`${s.modal__modal_content}`}>
					{isCloseModalVisible && (
						<div
							className={`absolute end-0 top-0 p-1 ${s.modal__close_container}`}
						>
							{!isSubModalOpen && (
								<button
									type="button"
									className={`${s.modal__close_button}`}
									data-bs-dismiss="modal"
									aria-label="Close"
									onClick={handleCloseModal}
								>
									<IoClose />
								</button>
							)}
						</div>
					)}
					<div
						id="main_modal"
						className={`${s.modal__modal_body}`}
						style={{
							background: hapeModalGradient,
						}}
					>
						<div
							className={`${s.modal__hape_container}`}
							style={cny24LanternsEnhancedBackgroundStyles}
						>
							<div
								className={`${s.modal__left_side} ${
									modalTab === "equip" ? s.modal__left_side_equip : ""
								}`}
								ref={leftSideRef}
							>
								<div className={`${s.modal__hape_pfp_outside}`}>
									<div
										id="LayeredExtra"
										className={`${s.modal__layered_extra_background}`}
									>
										{layeredExtra && (
											<img
												key={layeredExtra}
												className={`${s.modal__hape_pfp} ${s.modal__layered_extra}`}
												src={`/static/img/backgrounds/${layeredExtra}`}
												alt={"Loading..."}
												width={getImageDimensions() * 2}
												height={getImageDimensions()}
											/>
										)}
									</div>
									<div
										className={`${
											!isMobileAndOnEquipPanel()
												? s.modal__hape_pfp_background
												: s.modal__mobile_equip_hape_pfp_background
										}`}
									>
										<div
											className={s.modal__hape_pfp_background_2}
											style={{
												opacity: isDavenatorImageLoaded ? 0 : 1,
											}}
										>
											<img
												key={imageSrc}
												className={`${
													!isMobileAndOnEquipPanel()
														? s.modal__dynamic_pfp
														: s.modal__mobile_equip_dynamic_pfp
												}`}
												src={imageSrc}
												alt={"Loading..."}
												width={getImageDimensions()}
												height={getImageDimensions()}
											/>
										</div>
										<div
											className={s.modal__hape_pfp_background_2}
											style={{
												opacity: isDavenatorImageLoaded ? 1 : 0,
											}}
										>
											<img
												key={davenatorImageSrc}
												className={`${
													!isMobileAndOnEquipPanel()
														? s.modal__dynamic_pfp
														: s.modal__mobile_equip_dynamic_pfp
												}`}
												src={davenatorImageSrc}
												alt={"Loading..."}
												width={getImageDimensions()}
												height={getImageDimensions()}
											/>
										</div>

										{isDavenatorImageLoaded &&
											isMobile &&
											rightExtra &&
											modalTab !== "equip" && (
												<div
													className={`${s.modal__hape_pfp_background} ${s.modal__top_extra}`}
												>
													<img
														key={rightExtra}
														className={`${s.modal__hape_pfp} ${s.modal__layered_extra}`}
														src={`/static/img/backgrounds/${rightExtra}`}
														alt={"Loading...2"}
														width={getImageDimensions()}
														height={getImageDimensions()}
													/>
												</div>
											)}
										{((rightExtra && !isMobile) ||
											(isMobile && rightExtra && modalTab !== "equip")) && (
											<div
												id="RightExtra"
												className={`${s.modal__hape_pfp_background} ${s.modal__right_extra}`}
											>
												{isDavenatorImageLoaded && (
													<img
														key={rightExtra}
														className={`${s.modal__hape_pfp} ${s.modal__layered_extra}`}
														src={`/static/img/backgrounds/${rightExtra}`}
														alt={"Loading...3"}
														width={getImageDimensions()}
														height={getImageDimensions()}
													/>
												)}
											</div>
										)}
										{leftExtra && (
											<div
												id="LeftExtra?"
												className={`${s.modal__hape_tablet_left_extra} ${s.modal__left_extra}`}
											>
												<img
													key={leftExtra}
													className={`${s.modal__hape_pfp} ${s.modal__layered_extra}`}
													src={`/static/img/backgrounds/${leftExtra}`}
													alt={"Loading...4"}
													width={getImageDimensions()}
													height={getImageDimensions()}
												/>
											</div>
										)}
									</div>
									{/* <motion.div
										className={`${s.modal__hape_pfp_background}`}
										animate={wornBadgeControls}
										style={{ visibility: "hidden", opacity: 0 }}
									>
										{wornBadgeSrc && (
											<Image
												src={wornBadgeSrc}
												alt={"Loading..."}
												width={getImageDimensions()}
												height={getImageDimensions()}
												quality={100}
												onLoadStart={() => {
													setIsWornBadgeLoaded(false)
												}}
												onLoadingComplete={() => {
													setIsWornBadgeLoaded(true)
												}}
												priority={true}
											/>
										)}
									</motion.div> */}
									{/* <motion.div
										className={`${s.modal__hape_pfp_background}`}
										animate={badgeControls}
										style={{ visibility: "hidden" }}
									>
										{badgeSrc && (
											<Image
												src={badgeSrc}
												alt={"Loading...8"}
												width={getImageDimensions()}
												height={getImageDimensions()}
												quality={100}
												onLoadingComplete={() => setIsBadgeLoaded(true)}
											/>
										)}
									</motion.div> */}
									{/* <motion.div className={`${s.modal__hape_pfp_background}`}>
										{sparkle}
									</motion.div> */}
									{isMobile && (
										<div className={s.modal__hape_layered}>{tabContainer}</div>
									)}
									{isMobile && (
										<div
											className={`${s.modal__hape_layered} ${
												s.modal__mobile_stats_box
											} ${modalTab === "equip" ? "" : ""}`}
										>
											{statsBox}
										</div>
									)}
									{/* <button
										className={s.modal__export}
										onClick={() => {
											window.open(exportSrc, "_blank")
										}}
									>
										<Image
											src={`/static/img/equipment/modal_card/extras/export.svg`}
											alt={"Loading..."}
											width={50}
											height={50}
											quality={100}
										/>
									</button> */}
									{successfullyFetchedExportImageSource &&
										!isFetchingExportImage && (
											<DownloadImage
												exportImageSrc={exportImageSource}
												tokenId={card.token_id}
											/>
										)}
								</div>
							</div>
							{modalViewContainer}
						</div>
					</div>
				</div>
			</motion.div>
		</div>
	)
}

export default ModalCard

const DownloadImage = ({ exportImageSrc, tokenId }) => {
	return (
		<a
			download={`HAPE #${tokenId}.png`}
			href={exportImageSrc}
			className={s.modal__export}
		>
			<Image
				src={`/static/img/equipment/modal_card/extras/export.svg`}
				alt={"Loading..."}
				width={50}
				height={50}
				quality={100}
			/>
		</a>
	)
}

const StatsBox = ({
	startRank,
	endRank,
	startScore,
	endScore,
	isShowingRankChangeIndicator,
	setIsShowingRankChangeIndicator,
	isUpdatingRank,
	isMobile,
	votes,
}) => {
	const isIncreasing = endScore >= (startScore ?? 0)

	const [isDone, setIsDone] = useState(false)
	const duration = 300

	const textControls = useAnimation()

	useEffect(() => {
		setIsShowingRankChangeIndicator((prev) => prev + 1)
		textControls.start({
			opacity: 1,
		})

		if (endRank !== "0000" && !isUpdatingRank) {
			textControls.start({
				filter: "blur(0px)",
			})
		} else {
			if (isUpdatingRank) {
				textControls.start({
					filter: "blur(2px)",
				})
			}
		}
	}, [endRank, isUpdatingRank])

	return (
		<div className={s.stats_box_parent}>
			{!isMobile && (
				<PointsVotes
					endScore={endScore}
					startScore={startScore}
					votes={votes}
				/>
			)}
			<div className={s.stats_box_container}>
				{isMobile && <div className={s.rank_title}>Fashion Index Rank</div>}
				<div className={s.stats_box_parent}>
					<motion.span
						animate={textControls}
						style={{ opacity: 0 }}
						key={endRank}
						className={`${s.rank} ${isDone ? s.lift_blur : ""} ${
							endRank === "0000" ? s.modal__blur : ""
						}`}
					>
						{endRank}
					</motion.span>
				</div>
				{!isMobile && <div className={s.rank_title}>Fashion Index Rank</div>}
			</div>
		</div>
	)
}

const Preload = ({ src, dimensions }) => {
	return (
		<>
			<motion.div
				className={`${s.modal__hape_pfp_background}`}
				style={{ visibility: "hidden", opacity: 0 }}
			>
				<Image
					src={src}
					alt={"Loading..."}
					width={dimensions}
					height={dimensions}
					quality={100}
					priority={true}
				/>
			</motion.div>
		</>
	)
}
