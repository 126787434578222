import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { AxiosResponse } from "axios"
import { useAtom } from "jotai"
import { fashionIndexApi } from "../../utils/fashion-index/api"
import { walletAddressAtom } from "./inventoryServices"

const getMyHapes = async (userWalletAddress): Promise<any> => {
	const url = `get-my-hapes/${userWalletAddress}/` // Adjusted URL, no longer appending the wallet address in the path
	const response: AxiosResponse<any[]> = await fashionIndexApi.get(url)
	return response.data
}

export const useGetMyHapes = (): UseQueryResult<any> => {
	// Access walletAddress and hapeId from Jotai atoms
	const [walletAddress] = useAtom(walletAddressAtom)
	return useQuery<any>({
		queryKey: ["getMyHapes", walletAddress],
		queryFn: () => getMyHapes(walletAddress),
		enabled: !!walletAddress,
		gcTime: Infinity,
	})
}
