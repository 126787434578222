import curryFileList from "../../zHapeStorage/curryDrive"
import twitterHeaders from "../../zHapeStorage/twitterHeaders"
import twitterHeaders2 from "../../zHapeStorage/twitterHeaders2"
import backgroundlessFileList from "../../zHapeStorage/backgroundlessDrive"
import handmadeFileList from "../../zHapeStorage/handmadeDrive"
import { jagermeisterApparel } from "../../zHapeStorage/jagermeisterApparel"
import { jagermeisterBottles } from "../../zHapeStorage/jagermeisterBottles"
import { halloweenJackFileList } from "../../zHapeStorage/halloweenJackDrive"
import { halloweenWitchDrive } from "../../zHapeStorage/halloweenWitchDrive"
import { movemberFileList } from "../../zHapeStorage/movemberDrive"
import { dieselBagsFileList } from "../../zHapeStorage/dieselBagsDrive"
import { dieselHoodiesFileList } from "../../zHapeStorage/dieselHoodiesDrive"

const listOfLocalFiles = [
	{
		key: "diesel_hoodie",
		label: "DIESEL",
		fileList: dieselHoodiesFileList
	},
	{
		key: "diesel_bag",
		label: "DIESEL",
		fileList: dieselBagsFileList
	},
	{
		key: "movember1",
		label: "Movember",
		fileList: movemberFileList
	},
	{
		key: "halloween_jack",
		label: "Halloween Jack",
		fileList: halloweenJackFileList
	},
	{
		key: "halloween_witch",
		label: "Halloween Witch",
		fileList: halloweenWitchDrive
	},
	{
		key: "jagermeister_apparel",
		label: "Jägermeister",
		fileList: jagermeisterApparel
	},
	{
		key: "jagermeister_bottles",
		label: "Jägermeister",
		fileList: jagermeisterBottles
	},
	{
		key: "twitter_header_1",
		label: "Twitter Header",
		fileList: twitterHeaders
	},
	{
		key: "twitter_header_2",
		label: "Twitter Header",
		fileList: twitterHeaders2
	},
	{
		key: "curry",
		label: "Curry",
		fileList: curryFileList
	}
]

export async function getAllFiles(hape_token_id: number) {
	const highSnobiety = await getHighSnobietyFile(hape_token_id)
	const profilePicture = `https://meta.hapeprime.com/${hape_token_id}.png`
	const isolated = await getImageFile(
		hape_token_id,
		backgroundlessFileList,
		"png"
	)
	const theseFiles = [
		{
			key: "high_snobiety",
			label: "HighSnobiety",
			imageLink: highSnobiety
		},
		{
			key: "isolated",
			label: "Isolated",
			imageLink: isolated
		},
		{
			key: "pfp",
			label: "Pfp",
			imageLink: profilePicture
		}
	]

	const otherFiles = await Promise.all(
		listOfLocalFiles.map(async file => {
			const thisFile = await getImageFile(hape_token_id, file.fileList, "jpg")
			return { key: file.key, label: file.label, imageLink: thisFile }
		})
	)

	return [...otherFiles, ...theseFiles]
}

async function getHighSnobietyFile(hape_token_id: number) {
	let fileId = ""
	let handmade

	const weird = [1474, 6688]
	if (weird.includes(hape_token_id)) {
		handmade = handmadeFileList.find(h => h.name === `${hape_token_id}.jpg`)
	} else {
		handmade = handmadeFileList.find(h => h.name === `${hape_token_id}.png`)
	}

	if (handmade) {
		fileId = handmade.id
	}

	const finalLink = await fetch(
		`https://www.googleapis.com/drive/v2/files/${fileId}?key=AIzaSyBB3Ds6VY2kYbzg4U-rrQAmPAcTew6ZU0w`
	).then(response => response.json())
	if (finalLink["alternateLink"]) {
		return finalLink["alternateLink"]
	}
}

async function getImageFile(
	hape_token_id: number,
	localFile: any[],
	fileType: string
) {
	let fileId = ""
	let file

	file = localFile.find(h => h.name === `${hape_token_id}.${fileType}`)

	if (file) {
		fileId = file.id
	}

	const finalLink = await fetch(
		`https://www.googleapis.com/drive/v2/files/${fileId}?key=AIzaSyBB3Ds6VY2kYbzg4U-rrQAmPAcTew6ZU0w`
	).then(response => response.json())
	if (finalLink["alternateLink"]) {
		return finalLink["alternateLink"]
	}
}
