import { NextSeo } from "next-seo"
import { useRouter } from "next/router"

export default function Header() {
	const title = "HAPE | FASHION INDEX - Public Beta"
	const desc =
		"The official NFT ranking tool. Equip items to change your appearance for rank bonuses, explore traits and compete for the best HAPE Fashion Index Score."
	const ogImgRelativePath = "/og.png"

	const siteURL = "https://index.hape.io"
	const ogImageURL = `https://i.imgur.com/wctSjPu.png`
	const pathName = useRouter().pathname
	const pageURL = pathName === "/" ? siteURL : siteURL + pathName
	const twitterHandle = "@HAPEsocial"
	const siteName = "index.hape.io"

	return (
		<NextSeo
			title={title}
			description={desc}
			canonical={pageURL}
			openGraph={{
				type: "website",
				locale: "en_US", //  Default is en_US
				url: pageURL,
				title,
				description: desc,
				images: [
					{
						url: ogImageURL,
						width: 1200,
						height: 630,
						alt: "HAPE | Fashion Index"
					}
				],
				site_name: siteName
			}}
			twitter={{
				handle: twitterHandle,
				site: twitterHandle,
				cardType: "summary_large_image"
			}}
			additionalMetaTags={[
				{
					property: "author",
					content: title
				}
			]}
			additionalLinkTags={[
				{
					rel: "icon",
					href: `${siteURL}/favicon_rebrand.ico`
				}
				// {
				//   rel: "manifest",
				//   href: "/site.manifest",
				// },
			]}
		/>
	)
}
