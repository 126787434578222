import { useAtom } from "jotai"
import { useEffect } from "react"
import {
	activeCategoryAtom,
	hoverTraitAtom,
	useHapeEquippedTraits,
} from "../../../../../services/fashion-index/inventoryServices"
import {
	ClothingMapping,
	CollectionMapping,
} from "../../../../../utils/dynamic-equipment/clothingMapping"
import {
	getThumbnailImageUri,
	itemTitleImgSizing,
} from "../../../../../utils/dynamic-equipment/helpers"
import s from "../Equipment2.module.scss"

const RarityColorLookup = {
	common: "7E7E7E",
	uncommon: "75F149",
	rare: "17C0E5",
	legendary: "F19A49",
	mythic: "D71717",
	epic: "6934FF",
}

const ItemTitle = ({ item }) => {
	const [hoverTrait] = useAtom(hoverTraitAtom)
	const [activeCategory] = useAtom(activeCategoryAtom)
	const { data: currentlyEquippedTraits } = useHapeEquippedTraits()

	const equippedTraitInThisCategory = currentlyEquippedTraits.find(
		(trait) => trait.category === activeCategory,
	)

	const traitToDisplay =
		hoverTrait !== "" ? hoverTrait : equippedTraitInThisCategory

	const getFormattedItemTitle = () => {
		if (traitToDisplay && traitToDisplay["value"].includes("(")) {
			const openingParenthesisIndex = traitToDisplay["value"].indexOf("(")
			const closingParenthesisIndex = traitToDisplay["value"].indexOf(")")
			const name = traitToDisplay["value"].substring(
				0,
				openingParenthesisIndex - 1,
			)
			const color = traitToDisplay["value"].substring(
				openingParenthesisIndex + 1,
				closingParenthesisIndex,
			)
			return {
				name: name,
				color: color,
			}
		} else {
			return {
				name: traitToDisplay["value"],
				color: null,
			}
		}
	}

	const getCollectionName = () => {
		const mappedCollection = ClothingMapping.find(
			(m) => m.value.toLowerCase() === item?.value.toLowerCase(),
		)?.mappedCollection

		if (mappedCollection) {
			if (CollectionMapping[mappedCollection]) {
				return CollectionMapping[mappedCollection]
			}
		}
	}

	const getBgImage = () => {
		const bgImageUrl = `url(${getThumbnailImageUri(
			traitToDisplay["category"],
			traitToDisplay["value"],
		)})`

		return bgImageUrl
	}
	return (
		<>
			{traitToDisplay ? (
				<div
					className={s.item_details_section}
					style={{
						backgroundImage: getBgImage(),
						...itemTitleImgSizing[traitToDisplay["category"]],
					}}
				>
					<div className={s.item_details_container}>
						<div className={s.item_details_title_container}>
							<>
								{traitToDisplay ? (
									<>
										<div className={s.item_details_top_container}>
											{traitToDisplay["rarity_type"] && (
												<div
													className={s.item_details_rarity}
													style={{
														backgroundColor: `#${
															RarityColorLookup[
																traitToDisplay["rarity_type"].toLowerCase()
															]
														}`,
													}}
												>
													{traitToDisplay["rarity_type"]}
												</div>
											)}
											<span
												className={`${s.item_details_rarity} ${s.item_details_equipped}`}
											>
												{traitToDisplay["is_equipped_on_this_hape"]
													? "Equipped"
													: ""}
											</span>
										</div>
										<div className={s.item_details_title}>
											{getFormattedItemTitle().name}
										</div>
										{getFormattedItemTitle().color && (
											<div className={s.item_details_title}>
												{getFormattedItemTitle().color}
											</div>
										)}
										<div className={s.item_details_collection}>
											{getCollectionName()}
										</div>
									</>
								) : (
									<div className={s.item_details_top_container}>
										None Equipped
									</div>
								)}
							</>
						</div>
					</div>
				</div>
			) : (
				<div
					className={`${s.item_details_section}`}
					style={{
						backgroundImage: `url(/static/img/logos/hape_rebrand_logo_white.svg)`,
						backgroundSize: "30%",
						backgroundPositionX: "55%",
						backgroundPositionY: "51%",
					}}
				></div>
			)}
		</>
	)
}

export default ItemTitle
