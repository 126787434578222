import Image from "next/image"
import ChangingRoomWallet from "../../../WalletConnect/changingRoomWallet"
import s from "../Equipment2.module.scss"
import { isHandmade } from "../../../../../utils/dynamic-equipment/helpers"
import { useCategorizedInventory } from "../../../../../services/fashion-index/inventoryServices"
export default function BottomActions({
	hapeTokenId,
	localExportImageSrc,
	activeCategory,
	isShowingEquipmentPanel,
	setIsCloseModalVisible,
	setIsShowingEquipmentPanel,
	backToEquip,
	backToMainMenu,
	equipment,
}) {
	const getImageTabUrl = () => {
		let newExport: string = localExportImageSrc
		const pos = newExport.indexOf(".+")
		if (pos > -1) {
			newExport =
				newExport.substring(0, pos) + "/png" + newExport.substring(pos + 2)
			return newExport
		}
	}

	const { data: categorizedInventory } = useCategorizedInventory()

	return (
		<div className={s.bottom_primary}>
			{!equipment && isShowingEquipmentPanel && (
				<>
					{true ? (
						<>
							{!categorizedInventory && (
								<ChangingRoomWallet hapeTokenId={hapeTokenId} />
							)}
						</>
					) : (
						<div
							style={{
								fontSize: "0.8rem",
								padding: "1rem",
								textAlign: "center",
								color: "grey",
							}}
						>
							Handmade HAPEs are currently going through some extra love and
							care before being ready to get dressed! We apologize for the
							inconvenience.
						</div>
					)}
				</>
			)}
			<div
				className={`${s.mobile_bottom_actions} ${
					!isShowingEquipmentPanel ? s.background : ""
				}`}
			>
				<a
					className={s.mobile_bottom_button}
					download={`${hapeTokenId}.png`}
					style={{
						padding: 0,
						visibility: localExportImageSrc ? "visible" : "hidden",
					}}
					// onClick={() => {
					// 	let newExport: string = localExportImageSrc
					// 	const pos = newExport.indexOf(".+")
					// 	if (pos > -1) {
					// 		newExport =
					// 			newExport.substring(0, pos) +
					// 			"/png" +
					// 			newExport.substring(pos + 2)
					// 		var newTab = window.open("about:blank", "_blank")
					// 		newTab.document.body.innerHTML = `<img src="${newExport}" width="1100px" height="1100px">`
					// 	}
					// }}
					href={localExportImageSrc}
				>
					<Image
						src={`/static/img/equipment/modal_card/extras/export.svg`}
						alt={"Loading..."}
						width={39}
						height={39}
						quality={100}
					/>
				</a>

				{!activeCategory && !isShowingEquipmentPanel && (
					<button
						className={s.mobile_choose_traits}
						onClick={() => {
							setIsCloseModalVisible(false)
							setIsShowingEquipmentPanel(true)
						}}
					>
						Choose Traits
					</button>
				)}
				{!activeCategory && isShowingEquipmentPanel && (
					<button className={s.mobile_choose_traits} onClick={backToEquip}>
						Go Back
					</button>
				)}
				{activeCategory && (
					<button className={s.mobile_choose_traits} onClick={backToMainMenu}>
						Go Back
					</button>
				)}
				<button
					style={{ visibility: "hidden" }}
					className={s.mobile_bottom_button}
				></button>
			</div>
		</div>
	)
}
