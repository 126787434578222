import React, { useEffect, useState } from "react"
import s from "./Equipment.module.scss"
import Image from "next/image"
import { motion, AnimatePresence } from "framer-motion"
import { ItemType } from "../../../../utils/enum/constants"

export default function BadgeWristbandInventory({
	equipment,
	availableItems,
	unavailableItems,
	equipItem,
	equippedItem,
	getEquipmentDisplayName,
	getEquipmentObject,
	getEquipmentFolder,
	itemType,
	getDimensions,
	fillerGrid,
	isMobile,
	isMoreThanMax
}) {
	return (
		<div
			className={s.equipment_inner_container}
			style={{ overflowY: !isMobile && isMoreThanMax ? "auto" : "hidden" }}
		>
			{unavailableItems &&
				unavailableItems.map(e => (
					<div className={`${s.item}`} key={e.tokenId}>
						<AnimatePresence>
							<motion.div
								key={e?.token_id}
								className={s.wristband_container}
								initial={{ y: 10 }}
								animate={{ y: 0, opacity: 1 }}
								exit={{ y: 10, opacity: 0 }}
							>
								<motion.div
									key={e?.token_id + "lock"}
									className={
										itemType === ItemType.Wristband
											? s.wristband_equip_check
											: itemType === ItemType.Badge
											? s.badge_equip_check
											: ""
									}
									initial={{ y: 10 }}
									animate={{ y: 0, opacity: 1 }}
									exit={{ y: 10, opacity: 0 }}
								>
									<Image
										id={`hape_img`}
										src={`/static/img/equipment/equipped_by_other_hape.svg`}
										alt={"Loading..."}
										priority={true}
										width={50}
										height={50}
										quality={100}
									></Image>
								</motion.div>
								<Image
									id={`hape_img`}
									className={`${s.equipped_on_other_hape}`}
									src={`/static/img/equipment/${getEquipmentFolder(itemType)}/${
										getEquipmentObject(e?.itemType).folderName
									}/${getEquipmentObject(e?.itemType).fileName}.png`}
									alt={"Loading..."}
									priority={true}
									width={getDimensions(itemType).width}
									height={getDimensions(itemType).height}
									quality={100}
									layout="fill"
									objectFit="contain"
								></Image>
							</motion.div>
						</AnimatePresence>
					</div>
				))}

			{equipment &&
				availableItems &&
				availableItems.map(w => {
					return (
						<div
							className={`${s.item}`}
							key={w?.tokenId}
							onClick={e => {
								equipItem(w, itemType)
							}}
						>
							<AnimatePresence>
								<motion.div
									key={w?.token_id}
									className={s.wristband_container}
									initial={{ y: 10, opacity: 0 }}
									animate={{ y: 0, opacity: 1 }}
									exit={{ y: 10, opacity: 0 }}
								>
									{equippedItem?.tokenId === w?.tokenId && (
										<motion.div
											key={w?.token_id + "checkmark"}
											className={`${
												itemType === ItemType.Wristband
													? s.wristband_equip_check
													: itemType === ItemType.Badge
													? s.badge_equip_check
													: ""
											}`}
											initial={{ y: 10, opacity: 0 }}
											animate={{ y: 0, opacity: 1 }}
											exit={{ y: 10, opacity: 0 }}
										>
											<Image
												id={`hape_img`}
												src={`/static/img/equipment/equipped_by_this_hape.svg`}
												alt={"Loading..."}
												priority={true}
												width={50}
												height={50}
												quality={100}
											></Image>
										</motion.div>
									)}
									<Image
										id={`hape_img`}
										src={`/static/img/equipment/${getEquipmentFolder(
											itemType
										)}/${getEquipmentObject(w?.itemType).folderName}/${
											getEquipmentObject(w?.itemType).fileName
										}.png`}
										alt={"Loading..."}
										priority={true}
										width={getDimensions(itemType).width}
										height={getDimensions(itemType).height}
										quality={100}
										layout="fill"
										objectFit="contain"
									></Image>
								</motion.div>
							</AnimatePresence>
						</div>
					)
				})}
			{fillerGrid.map((empty, i) => {
				return <div key={i} className={`${s.item} ${s.no_pointer}`}></div>
			})}
		</div>
	)
}
