import { Grid, WindowScroller } from "react-virtualized"
import React, { useCallback, useEffect, useRef, useState } from "react"
import s from "./InfiniteScroll.module.scss"
import { useDebounce } from "use-debounce"
import useDidMountEffect from "../../hooks/useDidMountEffect"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { debounce } from "lodash"

export default function InfiniteScroll(props) {
	const {
		cards,
		parentRef,
		parentRefMobile,
		getMoreHapes,
		isMobile,
		setCardSize,
		isFilterSidebarOpen,
		isViewingMyHapes,
		handleNextPage,
		isLoading,
	} = props

	const windowSize = useWindowDimensions()

	// Debounced scroll handler to improve performance and reduce the number of API calls
	const handleScroll = debounce(async () => {
		if (
			window.innerHeight + document.documentElement.scrollTop + 500 >=
				document.documentElement.offsetHeight &&
			!isLoading &&
			!isViewingMyHapes
		) {
			handleNextPage()
		}
	}, 200) // Adjust debounce time as needed

	useEffect(() => {
		window.addEventListener("scroll", handleScroll)

		// Cleanup on component unmount
		return () => {
			window.removeEventListener("scroll", handleScroll)
			handleScroll.cancel() // Cancel the debounced function on cleanup to prevent it from executing after component unmount
		}
	}, [handleScroll, isLoading])

	const [windowWidth, setWindowWidth] = useState(0)
	const [filterOpenWindowWidth, setFilterOpenWindowWidth] = useState(0)
	const [filterClosedWindowWidth, setFilterClosedWindowWidth] = useState(0)

	useEffect(() => {
		const handleResize = () => {
			let ref
			if (isMobile) {
				ref = parentRefMobile
			} else {
				ref = parentRef
			}
			if (ref.current) {
				setWindowWidth(ref.current.clientWidth)
				if (isFilterSidebarOpen) {
					setFilterOpenWindowWidth(ref.current.clientWidth)
				} else {
					setFilterClosedWindowWidth(ref.current.clientWidth)
				}
			}
		}

		handleResize()
		window.addEventListener("resize", handleResize)
		return () => window.removeEventListener("resize", handleResize)
	}, [parentRef?.current?.clientWidth, parentRefMobile?.current?.clientWidth])

	const [debouncedWindowWidth] = useDebounce(windowWidth, 200)
	const [columnCount, setColumnCount] = useState(6)

	useEffect(() => {
		const isMobileOrTablet = windowSize.width < 1000
		const isMobile = windowSize.width < 500
		if (isViewingMyHapes) {
			setColumnCount(2)
		} else if (isMobile) {
			setColumnCount(3)
		} else if (isFilterSidebarOpen && !isMobileOrTablet) {
			setColumnCount(5)
		} else if (!isFilterSidebarOpen && !isMobileOrTablet) {
			setColumnCount(6)
		} else if (isMobileOrTablet && !isFilterSidebarOpen) {
			setColumnCount(Math.ceil(windowSize.width / 250))
		}
	}, [isFilterSidebarOpen, windowSize, isViewingMyHapes])

	const [rowCount, setRowCount] = useState(1)
	useEffect(() => {
		setRowCount(Math.ceil(cards.length / columnCount))
	}, [columnCount, cards])

	const [gridKey, setGridKey] = useState(0)
	const [columnWidth, setColumnWidth] = useState(0)
	const [rowHeight, setRowHeight] = useState(0)
	useEffect(() => {
		const squareMeasurement = debouncedWindowWidth / columnCount
		if (isFilterSidebarOpen && debouncedWindowWidth === filterOpenWindowWidth) {
			setColumnWidth(squareMeasurement)
			setRowHeight(squareMeasurement)
			setCardSize(squareMeasurement)
		} else if (
			!isFilterSidebarOpen &&
			debouncedWindowWidth === filterClosedWindowWidth
		) {
			setColumnWidth(squareMeasurement)
			setRowHeight(squareMeasurement)
			setCardSize(squareMeasurement)
		}

		// Force the grid to re-render by incrementing grid key
		setGridKey(gridKey + 1)
	}, [columnCount, debouncedWindowWidth, isMobile])

	const cellRenderer = ({ columnIndex, rowIndex, key, style }) => {
		const cardIdx = columnCount * rowIndex + columnIndex
		return (
			<div key={key} style={style}>
				<div className={`${s.card_container}`}>{cards[cardIdx]}</div>
			</div>
		)
	}

	const renderGrid = (inputs) => {
		const { height, isScrolling, onChildScroll, scrollTop, width } = inputs

		return (
			<div className={s.grid_container}>
				<Grid
					className={s.grid}
					autoHeight
					autoWidth
					columnCount={columnCount}
					columnWidth={columnWidth}
					height={height}
					rowCount={rowCount}
					rowHeight={rowHeight}
					width={width}
					// Handles placement of cards
					cellRenderer={cellRenderer}
					// Provide situational awareness for DOM recycling
					isScrolling={isScrolling}
					onScroll={onChildScroll}
					scrollTop={scrollTop}
					// Forces full re-render on window size change
					key={gridKey}
				/>
			</div>
		)
	}

	return <WindowScroller>{renderGrid}</WindowScroller>
}
