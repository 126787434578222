import { CSSProperties, useEffect } from "react"
import toast from "react-hot-toast"

const useDavenatorToast = (
	data,
	isFetching,
	isSuccess,
	isError,
	deactivate = false,
) => {
	const toastId = "davenator-toast"
	const toastStyle: CSSProperties = {
		backgroundColor: "#141414",
		color: "white",
	}

	useEffect(() => {
		if (!deactivate) {
			// If the query is loading, show a loading toast or update the existing one
			if (isFetching) {
				toast.loading("Dressing Hape", {
					id: toastId,
					position: "bottom-center",
					style: { ...toastStyle },
				})
			} else if (isError) {
				// If the query encountered an error, show or update the toast with the error message
				toast.error("Failed to dress Hape", {
					id: toastId,
					style: { ...toastStyle },
				})
			} else if (isSuccess && data) {
				// If the query succeeded, show or update the toast with the success message
				toast.success("Finished dressing Hape", {
					id: toastId,
					style: { ...toastStyle },
				})
			}

			// Cleanup function to dismiss the toast when the component using this hook unmounts
			return () => {
				toast.dismiss(toastId)
			}
		}
	}, [isFetching, isSuccess, isError, data])
}

export default useDavenatorToast
