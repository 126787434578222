const EquipToast = ({ hapeId, rank, fashionScore }) => {
	return (
		<div className="flex w-full items-center justify-between text-white">
			<div>
				<span className="text-2xl">HAPE #{hapeId}</span>
			</div>

			<div className="text-center">
				<div className="text-sm font-semibold">Rank</div>
				<div>
					<div className="text-lg" style={{ padding: "2px 4px" }}>
						{rank}
					</div>
				</div>
			</div>
			<div className="text-center">
				<div className="text-sm font-semibold">Fashion Score</div>
				<div>
					<div className="text-lg" style={{ padding: "2px 4px" }}>
						{Math.round(fashionScore)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default EquipToast
