import { useAtom } from "jotai"
import Image from "next/image"
import { useContext, useEffect } from "react"
import { isWeb3AuthenticatedAtom } from "../../../services/fashion-index/AuthServices"
import {
	useCategorizedInventory,
	useCheckHapeOwner,
} from "../../../services/fashion-index/inventoryServices"
import { ModalContext } from "../../contexts/ModalContext"
import { WalletContext } from "../../contexts/WalletContext"
import s from "./WalletConnect.module.scss"

export default function ChangingRoomWallet({ hapeTokenId }) {
	const {
		connect,
		disconnect,
		getFormattedAddress,
		web3Provider,
		isAddressOwnerOf,
		isCheckingOwnerOf,
		isSigning,
		signToGetEquipment,
		hasSigned,
		isGettingEquipment,
		checkOwnerOf,
		equipment,
		signToGetDynamicEquipment,
		signToAuthenticateWalletLegacy,
	} = useContext(WalletContext)

	const { hapeColor } = useContext(ModalContext)
	const {
		data: isUserOwnerOfThisHape,
		isFetching: isFetchingIsUserOwnerOfThisHape,
	} = useCheckHapeOwner()
	const [isWeb3Authenticated] = useAtom(isWeb3AuthenticatedAtom)
	const {
		isPending: isPendingCategorizedInventory,
		isFetching: isFetchingCategorizedInventory,
		isError: isErrorCategorizedInventory,
	} = useCategorizedInventory()

	return (
		<>
			<div className={s.container}>
				{!web3Provider && (
					<div
						className={s.equip_wallet_container}
						style={{ zIndex: 999 }}
						onClick={connect}
					>
						<div className={s.wallet_icon}>
							<Image
								src={`/static/img/wallet/wallet_closed.svg`}
								width={50}
								height={38.8}
								quality={100}
								alt={"Wallet"}
							/>
						</div>
						<div className={s.text_container}>
							<div className={s.top_text}>Connect?</div>
							<div className={`${s.bottom_text} ${s.open_wallet}`}>
								Connect Wallet
							</div>
						</div>
					</div>
				)}
				{isUserOwnerOfThisHape && web3Provider && (
					<div className={`${s.bottom_text}`}>
						<div className={s.center} onClick={disconnect}>
							<Image
								src={`/static/img/wallet/wallet_closed.svg`}
								width={30}
								height={30}
								quality={100}
								alt={"Wallet"}
							/>
						</div>
						<div className={s.center}>{getFormattedAddress()}</div>
					</div>
				)}
				{!isFetchingIsUserOwnerOfThisHape &&
					isUserOwnerOfThisHape &&
					web3Provider && (
						<div className={`${s.bottom_text} ${s.center}`}>
							{(isSigning && (
								<>
									<div>Sign to enter...</div>
								</>
							)) ||
								(isFetchingCategorizedInventory && (
									<div>Opening closet...</div>
								)) ||
								(!isFetchingCategorizedInventory && (
									<button
										className={s.closet}
										onClick={() => signToAuthenticateWalletLegacy()}
									>
										Open Closet
									</button>
								))}
						</div>
					)}
				{!isUserOwnerOfThisHape && web3Provider && (
					<div className={`${s.bottom_text} ${s.center}`}>
						{isFetchingIsUserOwnerOfThisHape ? (
							<div>Verifying HAPE ownership...</div>
						) : (
							<div>Access to inventory requires ownership.</div>
						)}
					</div>
				)}
				{hasSigned && (
					<div className={`${s.bottom_text} ${s.center}`}>
						{isGettingEquipment && <div>Entering Changing Room...</div>}
					</div>
				)}
			</div>
		</>
	)
}
