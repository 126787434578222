import { useAtom, useSetAtom } from "jotai"
import { FC, useCallback, useState } from "react"
import {
	hapeIdAtom,
	hoverTraitAtom,
	useCategorizedInventory,
	useEquipTrait,
	useUnequipTrait,
	walletAddressAtom,
} from "../../../../services/fashion-index/inventoryServices"
import { getThumbnailImageUri } from "../../../../utils/dynamic-equipment/helpers"
import {
	EquipTraitRequestParams,
	TraitItem,
	UnequipTraitRequestParams,
} from "../../../types/fashionIndex/InventoryTypes"
import { Card } from "../../ui/card"
import { motion, AnimatePresence, useAnimation } from "framer-motion"
import { LoadingSpinner } from "../../ui/loading-spinner"
import { toast } from "react-hot-toast"
import { Badge } from "../../ui/badge"
import EquipToast from "../../HotToast/EquipToast"

interface InventoryTraitCardProps {
	trait: TraitItem
}

const InventoryTraitCard: FC<InventoryTraitCardProps> = ({ trait }) => {
	const thumbnail = getThumbnailImageUri(trait.category, trait.value, true)
	const hoverOpacityControls = useAnimation()
	const { mutate: equipTrait, mutateAsync: equipTraitAsync } = useEquipTrait()
	const { mutate: unequipTrait, mutateAsync: unequipTraitAsync } =
		useUnequipTrait()

	const [hapeId] = useAtom(hapeIdAtom)
	const [walletAddress] = useAtom(walletAddressAtom)
	const setHoverTrait = useSetAtom(hoverTraitAtom)
	const { isLoading: isLoadingCategorizedInventory } = useCategorizedInventory()
	const [isLoadingTraitImage, setIsLoadingTraitImage] = useState(true)

	const equipToastId = "equip-toast-id"
	const handleEquipTraitRequest = async (item: TraitItem) => {
		const equipTraitRequestBody: EquipTraitRequestParams = {
			hape_id: hapeId,
			trait_to_equip: {
				trait_type: item.trait_type, // "NFT", "Native", or "Global"
				equip_id: item.equip_id,
				category: item.category,
				value: item.value,
			},
		}

		toast.dismiss(equipToastId)

		toast.promise(
			equipTraitAsync(equipTraitRequestBody),
			{
				loading: "Equipping Trait...",
				error: "Failed to Equip. Please refresh page.",
				success: (data) => (
					<EquipToast
						hapeId={data.hape_id}
						rank={data.new_rank}
						fashionScore={data.new_fashion_score}
					/>
				),
			},
			{
				id: equipToastId,
				style: {
					width: "700px",
					backgroundColor: "#141414",
					color: "white",
				},
				duration: 5000,
				position: "bottom-center",
			},
		)
	}

	const handleUnequipTraitRequest = (item: TraitItem) => {
		const unequipTraitRequestBody: UnequipTraitRequestParams = {
			wallet_address: walletAddress,
			hape_id: hapeId,
			trait_to_unequip: {
				trait_type: item.trait_type, // "NFT", "Native", or "Global"
				equip_id: item.equip_id,
				category: item.category,
				value: item.value,
			},
		}

		toast.dismiss(equipToastId)

		toast.promise(
			unequipTraitAsync(unequipTraitRequestBody),
			{
				loading: "Equipping Trait...",
				error: "Failed to Equip. Please refresh page.",
				success: (data) => (
					<EquipToast
						hapeId={data.hape_id}
						rank={data.new_rank}
						fashionScore={data.new_fashion_score}
					/>
				),
			},
			{
				id: equipToastId,
				style: {
					width: "700px",
					backgroundColor: "#141414",
					color: "white",
				},
				duration: 5000,
				position: "bottom-center",
			},
		)
	}

	const handleEquipActions = (item: TraitItem) => {
		if (!isLoadingCategorizedInventory) {
			if (item.is_equipped_on_this_hape) {
				handleUnequipTraitRequest(item)
			} else {
				if (!item.is_locked) {
					handleEquipTraitRequest(item)
				}
			}
		}
	}

	const checkmarkSize = 40
	const thumbnailSize = 144

	const handleHoverStart = () => {
		setHoverTrait(trait)
		hoverOpacityControls.start({ opacity: 1 })
	}

	const handleHoverEnd = () => {
		setHoverTrait("")
		hoverOpacityControls.start({ opacity: 0.6 })
	}

	return (
		<button
			className="m-0 h-36 w-36 border-none bg-none p-0"
			onClick={() => handleEquipActions(trait)}
		>
			<Card className="relative h-36 w-36 overflow-hidden border-0 shadow-none">
				{trait.is_equipped_on_this_hape && (
					<motion.div
						className="z-100 absolute right-0 top-0 h-7 w-7"
						key={trait.equip_id}
						initial={{ y: 10, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: 10, opacity: 0 }}
					>
						<motion.img
							className="h-full w-full"
							src={`/static/img/equipment/equipped_by_this_hape.svg`}
							width={checkmarkSize}
							height={checkmarkSize}
							alt="equipped"
						/>
					</motion.div>
				)}
				<motion.div
					className={`relative flex aspect-square items-center justify-center p-2`}
					onHoverStart={handleHoverStart}
					onHoverEnd={handleHoverEnd}
					transition={{ duration: 0.2 }}
				>
					{isLoadingTraitImage && <LoadingSpinner color="darkgrey" />}
					<motion.img
						alt="Clothing Thumbnail"
						className={`absolute inset-0 aspect-square object-contain ${trait.is_equipped_on_this_hape ? "opacity-100" : ""}`}
						src={thumbnail}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null // prevents looping
							currentTarget.src =
								"/static/img/dynamic-equipment/placeholders/unequip/Clothing.svg"
						}}
						height={thumbnailSize}
						width={thumbnailSize}
						animate={hoverOpacityControls}
						initial={{ opacity: 0.6 }}
						onLoad={() => setIsLoadingTraitImage(false)}
					/>
					{trait.is_locked && (
						<img
							src="/static/img/equipment/lock.svg"
							alt="locked"
							className="absolute left-0.5 top-0.5 size-8 -translate-x-0.5 -translate-y-0.5"
						/>
					)}
				</motion.div>
			</Card>
		</button>
	)
}

export default InventoryTraitCard
