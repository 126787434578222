import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { AxiosResponse } from "axios"
import { processDynamicTraitsForDavenator } from "../../utils/dynamic-equipment/helpers"
import { fashionIndexApi } from "../../utils/fashion-index/api"
import { fetchGenerateDavenator } from "./inventoryServices"

export const fetchHapeEquippedTraitsForGalleryCard = async (
	hapeId: string,
): Promise<any> => {
	const url = `get-hape-equipped-traits-for-gallery-card/${hapeId}/`
	const response: AxiosResponse<any[]> = await fashionIndexApi.get(url)
	return response.data
}

export function useGetDavenatorImageForCard(
	hapeId: string,
	isResetToNative: boolean = true,
): UseQueryResult<any> {
	return useQuery<any>({
		queryKey: ["davenatorImageForCard", hapeId],
		queryFn: async () => {
			const equippedTraits = await fetchHapeEquippedTraitsForGalleryCard(hapeId)
			const {
				traitsFormattedForDavenator,
				traitsForLayering,
				deactivateBackgrounds,
			} = processDynamicTraitsForDavenator(equippedTraits, hapeId, true)

			const img = await fetchGenerateDavenator(traitsFormattedForDavenator)

			return img
		},
		enabled: !!hapeId && !isResetToNative,
		gcTime: Infinity,
	})
}
