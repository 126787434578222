import { Box } from "@chakra-ui/react"
import { useMutation } from "@tanstack/react-query"
import { useAtom } from "jotai"
import { Fragment, useEffect, useState } from "react"
import {
	hapeIdAtom,
	inventoryAtom,
	postEquipTrait,
	useCategorizedInventory,
	useEquipTrait,
	walletAddressAtom,
} from "../../../../services/fashion-index/inventoryServices"
import {
	EquipTraitRequestParams,
	TraitItem,
	TraitType,
} from "../../../types/fashionIndex/InventoryTypes"
import InventoryTraitCard from "./InventoryTraitCard"

const ItemEquipSection = ({ activeCategory }) => {
	const { data: categorizedInventory } = useCategorizedInventory()

	const traitData = categorizedInventory
		? categorizedInventory[activeCategory]
		: null

	return (
		<div className="flex flex-wrap content-start justify-center gap-2 overflow-y-auto p-3 sm:w-full lg:w-3/4">
			{traitData ? (
				traitData.map((trait: TraitItem, index) => (
					<Fragment key={`${trait.equip_id}`}>
						<InventoryTraitCard trait={trait} />
					</Fragment>

					// <div className="m-5" key={trait.equip_id}>
					// 	<button onClick={() => handleEquipTrait(trait)}>
					// 		{trait.value} {trait.is_equipped_on_this_hape ? "*" : ""}{" "}
					// 		{trait.is_locked ? `x ${trait.equipped_on_other_hape_id}` : ""}
					// 	</button>
					// </div>
				))
			) : (
				<div>No Traits in this Category</div>
			)}
		</div>
	)
}

export default ItemEquipSection
