import { useContext, useEffect, useRef } from "react";
import { useCategorizedInventory } from "../../../../../services/fashion-index/inventoryServices";
import { allWearableCategories } from "../../../../../utils/dynamic-equipment/helpers";
import { WalletContext } from "../../../../contexts/WalletContext";
import ChangingRoomWallet from "../../../WalletConnect/changingRoomWallet";
import ItemEquipSection from "../../equip-dynamic/ItemEquipSection";
import s from "../Mobile-Equipment.module.scss";
import ItemsSection from "./items-section";

export default function MobileEquipment({
	panelItems,
	activeCategory,
	setActiveCategory,
	hapeTokenId,
	itemTitle,
}) {
	const { data: categorizedInventory } = useCategorizedInventory();

	const { equipment, equipmentSignature } = useContext(WalletContext);

	const itemsSectionRef = useRef(null);

	useEffect(() => {
		console.log(activeCategory);
		if (itemsSectionRef.current) {
			itemsSectionRef.current.scrollTop = 0;
		}
	}, [activeCategory]);
	return (
		<div
			className={`${s.equipment_grid} ${
				activeCategory ? s.gradient_background : ""
			}`}
		>
			<div className={s.inner_container}>
				{categorizedInventory ? (
					<div style={{ marginTop: "2rem", height: "100%" }}>
						{!activeCategory ? (
							<>
								<div className={s.title}>Tap to Equip</div>
								<div className={s.grid}>
									{allWearableCategories.map((category) => {
										return (
											<div
												className={s.category}
												onClick={() => setActiveCategory(category)}
												key={category}
											>
												<div className={s.icon_category}>
													<div className={s.icon}>
														<img
															src={`/static/img/white/${category}.svg`}
															width={25}
															height={25}
															alt={category}
														/>
													</div>
												</div>
												<div className={s.category_title}>{category}</div>
											</div>
										);
									})}
								</div>
							</>
						) : (
							<div
								ref={itemsSectionRef}
								style={{ height: "100%", overflow: "auto" }}
							>
								{itemTitle}
								<ItemEquipSection activeCategory={activeCategory} />
							</div>
						)}
					</div>
				) : (
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							flexDirection: "column",
							height: "100%",
						}}
					>
						<div>
							<div
								style={{
									marginTop: 25,
									color: "white",
									padding: "0rem 2rem",
									fontSize: "1.4vh",
									textAlign: "center",
								}}
								className={s.text_container}
							>
								<div style={{ fontSize: "1rem", fontWeight: 600 }}>
									Welcome to the Hape Equipment Panel
								</div>
								<br />
								It may not look like it, but trait-swapping is incredibly
								complex. We’re trying to account for an unfathomable number
								possible item combinations, and we’re probably not going to get
								it right the first time around.
								<br />
								<br />
								<b>For the moment, your changes won’t be saved</b> - you’re able
								to export your current outfit, but once you leave the index
								window, you’ll have to get dressed again. We’re working on this
								first.
								<br />
								<br />
								<b>Secondly:</b> just like in real life, not all items work with
								other items. If something is layering in a weird way, or the
								interface is bugging out: please let us know in the Drops
								Discord channel. We’ll do our best to fix bugs as they come up.
								<br />
								<br />
								Consider yourselves fashion explorers as we build this beautiful
								engine together. Be patient, Stay Hape, and have fun!
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
