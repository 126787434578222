import React, {
	Dispatch,
	FC,
	SetStateAction,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react"
import s from "./Modal.module.scss"
import DataView from "./data-view"
import FilesView from "./files-view"
import EquipView from "./equip-view/equip-view"
import gradients from "../../../zHapeStorage/gradientsFromGradientHapes"
import { getAllFiles } from "../../../utils/files-view/helpers"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { WalletContext } from "../../contexts/WalletContext"
import EquipViewTwo from "./equip-view/equip-2-view"
import {
	Hape,
	ModalCardEquippedEquipment,
	Profile,
	ProfileEquipmentDetails,
	Trait,
} from "../../types"
import { AnimatePresence, AnimationControls } from "framer-motion"
import { useAtom, useSetAtom } from "jotai"
import {
	hapeIdAtom,
	walletAddressAtom,
} from "../../../services/fashion-index/inventoryServices"
import { HapeDetails } from "../../types/fashionIndex/InventoryTypes"

interface ModalViewContainerProps {
	isMobile: boolean
	card: HapeDetails
	equippedEquipment: Profile
	modalCardEquippedEquipment: ModalCardEquippedEquipment
	setEquippedEquipment: Dispatch<SetStateAction<Profile>>
	setModalCardEquippedEquipment: Dispatch<
		SetStateAction<ModalCardEquippedEquipment>
	>
	exportSrc: string
	tabContainer: JSX.Element
	modalTab: string
	modalView: {}
	setModalView: Dispatch<SetStateAction<{}>>
	statsBox: JSX.Element
	setStats: Dispatch<any>
	setIsSubModalOpen: Dispatch<any>
	setIsUpdatingRank: Dispatch<SetStateAction<boolean>>
	setOldStats: Dispatch<any>
	votes: any
	startScore: number
	endScore: number
	setImageSrc: Dispatch<SetStateAction<string>>
	setExportImageSrc: Dispatch<SetStateAction<string>>
	exportImageComponent: JSX.Element
	setIsCloseModalVisible: Dispatch<SetStateAction<boolean>>
	setGradientLayers: (cardTraits) => void
	setIsLoadingHapeData: Dispatch<SetStateAction<boolean>>
}
const ModalViewContainer: FC<ModalViewContainerProps> = ({
	isMobile,
	card,
	equippedEquipment,
	modalCardEquippedEquipment,
	setEquippedEquipment,
	setModalCardEquippedEquipment,
	exportSrc,
	tabContainer,
	modalTab,
	modalView,
	setModalView,
	statsBox,
	setStats,
	setIsSubModalOpen,
	setIsUpdatingRank,
	setOldStats,
	votes,
	startScore,
	endScore,
	setImageSrc,
	setExportImageSrc,
	exportImageComponent,
	setIsCloseModalVisible,
	setGradientLayers,
	setIsLoadingHapeData,
}) => {
	const [loadingEquipment, setLoadingEquipment] = useState(true)
	const rightSideDivRef = useRef(null)

	const windowSize = useWindowDimensions()

	const [cardTraits, setCardTraits] = useState<Trait[]>([])
	const [birthday, setBirthday] = useState(null)
	const [heartNumber, setHeartNumber] = useState(null)

	const { checkOwnerOf, web3Provider } = useContext(WalletContext)

	useEffect(() => {
		//setBirthday(card.traits.find(t => t.category === "Birthday").value)
		//setHeartNumber(card.traits.find(t => t.category === "Heart Number").value)
		const loadHapeData = async () => {
			const allCategories = [
				"Accessory",
				"Clothing",
				"Duotone",
				"Earrings",
				"Enhanced Background",
				"Eyes",
				"Eyewear",
				"Face Addition",
				"Fur",
				"Gadget",
				"Haircut",
				"Handmade",
				"Head",
				"Headwear",
				"Implants",
				"Jewellery",
				"Nose Piercing",
				"Tattoos",
				"Teeth",
			]

			setIsLoadingHapeData(true)
			await fetch(`/api/getSingularHapeWithTraits?token_id=${card.token_id}`)
				.then((response) => response.json())
				.then((data) => {
					const traits = data.hape[0].traits
					console.log("traits")
					console.log(data.hape[0])
					const finalTraits: Trait[] = []
					allCategories.forEach((category) => {
						const thisTrait = traits.find((t) => t.category === category)
						if (thisTrait) {
							finalTraits.push({
								category: category,
								value: thisTrait.value,
								possession_qty: thisTrait.possession_qty,
								rarity_score: thisTrait.rarity_score,
								primary_color: thisTrait.primary_color,
								rarity_type: thisTrait.rarity_type,
								secondary_color: thisTrait.secondary_color,
								value_core: thisTrait.value_core,
							})
						}
					})
					setCardTraits([...finalTraits])
					// TODO: need to check enhanced background here?
					setGradientLayers([...finalTraits])
				})
				.catch((error) => {
					// Handle any errors
					console.error(error)
				})
			setIsLoadingHapeData(false)
		}
		loadHapeData()
	}, [])

	const [allFiles, setAllFiles] = useState(null)

	const { APP_URI } = process.env

	const [reloadEquipment, setReloadEquipment] = useState(true)

	useEffect(() => {
		const fetchEquipment = async (passToModalCard = false) => {
			setLoadingEquipment(true)
			const equipment: Profile = await fetch(
				`/api/inventory/getInventory?hapePrimeTokenId=${card.token_id}`,
			)
				.then((response) => response.json())
				.then((json) => json.data)
			setEquippedEquipment(equipment)
			setLoadingEquipment(false)
		}
		if (reloadEquipment) {
			//fetchEquipment()
			setReloadEquipment(false)
		}
	}, [modalTab])

	useEffect(() => {
		async function getFiles() {
			const files = await getAllFiles(parseInt(card.token_id))
			setAllFiles(files)
		}
		getFiles()
	}, [])

	useEffect(() => {
		setModalView({
			traits: (
				<DataView
					card={card}
					hapeTraits={cardTraits}
					equippedEquipment={equippedEquipment}
					isHapeOrNot={false}
					votes={votes}
					isMobile={isMobile}
					startScore={startScore}
					endScore={endScore}
				/>
			),
			files: (
				<FilesView
					hapeTokenId={card.token_id}
					allFiles={allFiles}
					isMobile={isMobile}
				/>
			),
			equip: (
				<EquipViewTwo
					card={card}
					hapeTraits={cardTraits}
					equippedEquipment={equippedEquipment}
					loadingEquipment={loadingEquipment}
					setReloadEquipment={setReloadEquipment}
					setModalCardEquippedEquipment={setModalCardEquippedEquipment}
					setStats={setStats}
					setOldStats={setOldStats}
					isMobile={isMobile}
					setIsSubModalOpenToMain={setIsSubModalOpen}
					windowSize={windowSize}
					setIsUpdatingRank={setIsUpdatingRank}
					setPrimaryImageSrc={setImageSrc}
					setExportImageSrc={setExportImageSrc}
					exportImageComponent={exportImageComponent}
					setIsCloseModalVisible={setIsCloseModalVisible}
					setGradientLayers={setGradientLayers}
				/>
			),
		})
	}, [
		card,
		JSON.stringify(cardTraits),
		equippedEquipment,
		loadingEquipment,
		allFiles,
		isMobile,
		modalCardEquippedEquipment,
		votes,
		startScore,
		endScore,
		setExportImageSrc,
	])

	const [rightGradient, setRightGradient] = useState("")
	const [rightGradientTopLayer, setRightGradientTopLayer] = useState("")
	const [rightGradientBlur, setRightGradientBlur] = useState("")
	const [backgroundSize, setBackgroundSize] = useState("contain")
	const customGradients = [2020, 996, 831, 1268, 1642]

	const hideStatsTabs = ["files", "equip"]

	const isMobileAndOnEquipPanel = () => {
		return isMobile && modalTab === "equip"
	}

	return (
		<div
			id="right_side"
			className={`${s.modal__right_side} ${
				modalTab === "equip" ? s.modal__equip_width : ""
			}`}
			ref={rightSideDivRef}
			// style={{
			// 	backgroundImage: `url(/static/img/backgrounds/${rightGradientTopLayer}), url(/static/img/backgrounds/${rightGradient})`,
			// 	backgroundSize: `${backgroundSize}`
			// }}
		>
			<AnimatePresence>
				{!isMobile ? tabContainer : ""}
				<div className={s.modal__right_side_inner_container}>
					{hideStatsTabs.includes(modalTab) ? (
						<div
							className={`${s.modal__files_connect_parent} ${
								isMobileAndOnEquipPanel() ? s.modal__mobile_equip_box : ""
							}`}
						>
							{modalView[modalTab]}
						</div>
					) : (
						<>
							{!isMobile ? statsBox : ""}
							{modalView[modalTab]}
						</>
					)}
				</div>
			</AnimatePresence>
		</div>
	)
}

export default ModalViewContainer
