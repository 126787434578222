import { useAtom } from "jotai"
import React, { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import {
	useCategorizedInventory,
	useResetHapeToNative,
	useSyncUserAndGetHapeInventory,
} from "../../../../services/fashion-index/inventoryServices"
import EquipToast from "../../HotToast/EquipToast"
import { Button } from "../../ui/button"
import ChangingRoomWallet from "../../WalletConnect/changingRoomWallet"

const CategorySelector = ({ categories, onCategoryChange, hapeTokenId }) => {
	const { mutate: resetHapeToNative, mutateAsync: resetHapeToNativeAsync } =
		useResetHapeToNative()
	const { mutate: syncUserAndGetHapeInventory } =
		useSyncUserAndGetHapeInventory()

	const { data: categorizedInventory } = useCategorizedInventory()

	const equipToastId = "equip-toast-id"
	const handleResetHapeToNative = () => {
		toast.dismiss(equipToastId)

		toast.promise(
			resetHapeToNativeAsync(),
			{
				loading: "Equipping Trait...",
				error: "Failed to Equip. Please refresh page.",
				success: (data) => (
					<EquipToast
						hapeId={data.hape_id}
						rank={data.new_rank}
						fashionScore={data.new_fashion_score}
					/>
				),
			},
			{
				id: equipToastId,
				style: {
					width: "700px",
					backgroundColor: "#141414",
					color: "white",
				},
				duration: 5000,
				position: "bottom-center",
			},
		)
	}

	const handleSyncUserAndGetHapeInventory = () => {
		syncUserAndGetHapeInventory()
	}

	return (
		<div className="w-48 border-r-2">
			<div>
				{categories.map((category, index) => (
					<div
						key={category}
						className={`flex h-12 w-full flex-row items-center ${index !== 0 ? "border-t-2" : ""} py-1 pl-7 ${index === categories.length - 1 ? "border-b-2" : ""}`}
					>
						<div className="opacity-0.5 mr-5 size-6">
							<img
								src={`/static/img/white/${category}.svg`}
								className="h-full w-full opacity-50"
								width={25}
								height={25}
								alt={category}
							/>
						</div>
						<button
							key={category}
							className="text-sm font-medium"
							onClick={() => onCategoryChange(category)}
						>
							{category}
						</button>
					</div>
				))}
			</div>
			<div>
				<br />
				{categorizedInventory && (
					<div className="text-center">
						<Button variant="outline" onClick={handleResetHapeToNative}>
							Reset Outfit
						</Button>
					</div>
				)}
			</div>
		</div>
	)
}

export default CategorySelector
