import { Box } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import ChangingRoomWallet from "../../WalletConnect/changingRoomWallet"
import CategorySelector from "./CategorySelector"
import ItemEquipSection from "./ItemEquipSection"
import {
	inventoryAtom,
	useCategorizedInventory,
	useResetHapeToNative,
} from "../../../../services/fashion-index/inventoryServices"
import { useAtom } from "jotai"
import { isHandmade } from "../../../../utils/dynamic-equipment/helpers"

const ClosetPanel = ({
	allWearableCategories,
	activeCategory,
	handleActiveCategoryChange,
	hapeTokenId,
}) => {
	const [inventoryItems, setInventoryItems] = useAtom(inventoryAtom)
	const { data: categorizedInventory } = useCategorizedInventory()

	const traitDataByCategory = inventoryItems
		? inventoryItems[activeCategory]
		: null

	useEffect(() => {
		console.log("inventoryitems", inventoryItems)
	}, [inventoryItems])

	return (
		<div
			className="flex w-full justify-between border-t-2"
			style={{ height: "82%" }}
		>
			<CategorySelector
				categories={allWearableCategories}
				onCategoryChange={handleActiveCategoryChange}
				hapeTokenId={hapeTokenId}
			/>
			{categorizedInventory ? (
				<ItemEquipSection activeCategory={activeCategory} />
			) : (
				<div className="flex justify-center sm:w-full lg:w-3/4">
					{true ? (
						<ChangingRoomWallet hapeTokenId={hapeTokenId} />
					) : (
						<div
							style={{
								fontSize: "0.8rem",
								padding: "1rem",
								textAlign: "center",
								color: "grey",
							}}
						>
							Handmade HAPEs are currently going through some extra love and
							care before being ready to get dressed! We apologize for the
							inconvenience.
						</div>
					)}
				</div>
			)}
		</div>
	)
}

export default ClosetPanel
